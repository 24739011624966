import * as React from "react";
import { styled } from "@mui/system";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const DialogPaper = styled(Dialog)(({ theme }) => ({
  padding: theme.spacing(1),
}));

const CloseButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  right: "0px",
  top: "-20px",
}));

const DialogTitleContainer = styled(DialogTitle)(({ theme }) => ({
  position: "relative",
}));

const SimpleDialog = ({
  open,
  setOpen,
  children,
  title,
  handleModalClose,
  maxWidth,
  height,
  hideCloseButton = false,
}) => {
  const handleClose = () => {
    setOpen?.(false);
  };

  return (
    <div>
      <DialogPaper
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: maxWidth,
            },
          },
        }}
        fullWidth
        disableEscapeKeyDown={!hideCloseButton}
        maxWidth={maxWidth}
        open={open}
        onClose={handleModalClose ? handleModalClose : handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitleContainer id="alert-dialog-title">
          {title}
          {!hideCloseButton && (
            <CloseButton
              aria-label="close"
              onClick={handleModalClose ? handleModalClose : handleClose}
            >
              <CloseIcon sx={{ height: 35, width: 35, mt: 2 }} />
            </CloseButton>
          )}
        </DialogTitleContainer>
        <DialogContent sx={{ height: height }}>{children}</DialogContent>
      </DialogPaper>
    </div>
  );
};

export default SimpleDialog;
