import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useState } from "react";
import IconLabelButton from "./IconLabelButton";
import { useInView } from "react-intersection-observer";
import styled from "styled-components";
import { Document, Page, pdfjs } from "react-pdf";
import { Box } from "@mui/system";

const TermsAndCondition = ({
  documentFile,
  open,
  setOpen,
  handlePolicyAgree,
  fromApplication = false,
  policyDocumentName,
  loading = false,
  title = "Privacy Notice",
}) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  const [numPages, setNumPages] = useState(false);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  const handleAgree = () => {
    !loading && handlePolicyAgree();
  };

  // to downlaod pdf
  const onDownloadClick = () => {
    fetch(documentFile).then((response) => {
      response.blob().then((blob) => {
        const fileURL = window.URL.createObjectURL(blob);
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = policyDocumentName ?? "download";
        alink.click();
      });
    });
  };
  return (
    <>
      <Dialog
        PaperProps={{
          style: {
            minWidth: "900px",
          },
        }}
        open={open}
        disableBackdropClick={true}
        transitionDuration={{ enter: 1000, exit: 700 }}
        disableEscapeKeyDown={true}
        BackdropProps={{ style: { backgroundColor: "rgba(0, 0, 0, 0.8)" } }}
        aria-describedby="alert-dialog-slide-description"
        style={{ padding: "30px" }}
      >
        <DialogTitle sx={{ textAlign: "start", fontSize: "1.3rem" }}>
          {title}
        </DialogTitle>
        {/* {documentFile && (
          <DialogContent>
            <Wrapper>
              <div>
                <Document
                  file={documentFile}
                  onLoadSuccess={onDocumentLoadSuccess}
                  loading=""
                >
                  {Array.from(new Array(numPages), (el, index) => (
                    <Page
                      key={`page_${index + 1}`}
                      pageNumber={index + 1}
                      className="pdf-class"
                    />
                  ))}
                  <Page pageNumber={pageNumber} />
                </Document>
                {numPages && <p ref={ref}></p>}
              </div>
            </Wrapper>
          </DialogContent>
        )} */}
        {documentFile && (
          <DialogContent>
            <Wrapper>
              <Box>
                <Document
                  file={documentFile}
                  options={{ workerSrc: "/pdf.worker.js" }}
                  onLoadSuccess={onDocumentLoadSuccess}
                >
                  {Array.from(new Array(numPages), (el, index) => (
                    <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                  ))}
                </Document>
                {numPages && <p ref={ref}></p>}
              </Box>
            </Wrapper>
          </DialogContent>
        )}
        <DialogActions
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <IconLabelButton
            variant="text"
            disableRipple={true}
            mylabel={
              fromApplication
                ? "Download terms of business."
                : "Download the privacy notice."
            }
            iconType="download"
            iconPosition="start"
            onClick={onDownloadClick}
          />
          <Box sx={{ display: "flex", gap: 5 }}>
            {fromApplication && (
              <IconLabelButton
                iconPosition="end"
                variant="text"
                mylabel="Cancel"
                onClick={() => setOpen(false)}
                iconType="close"
              />
            )}
            {!inView ? (
              <IconLabelButton
                iconPosition="end"
                variant="contained"
                mylabel="I Agree"
                buttonColor="gray"
              />
            ) : (
              <IconLabelButton
                iconPosition="end"
                variant="contained"
                mylabel="I Agree"
                onClick={handleAgree}
                iconType={loading ? "loading" : "chevron"}
                buttonColor="gradient"
              />
            )}
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TermsAndCondition;

export const Wrapper = styled.div`
  .react-pdf__Page__textContent {
    display: none;
  }
  .react-pdf__Page__canvas {
    width: 100% !important;
    height: auto !important;
  }
  .annotationLayer {
    width: 0 !important;
    height: 0 !important;
  }
`;
