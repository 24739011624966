import * as React from "react";
import isWeekend from "date-fns/isWeekend";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import enGB from "date-fns/locale/en-GB";
import { Box, Typography } from "@mui/material";
const StaticDatePickerLandscape = ({
  setCalendarValue = () => {},
  calendarValue = "",
  onChange = () => {},
}) => {
  return (
    <Box
      sx={{
        // border: '2px solid #E6E6EF',
        borderRadius: "5px",
        padding: "5px",
        pt: 0,
        maxWidth: 330,
        // margin: "0 auto",
      }}
    >
      <Typography variant="body1" sx={{ mt: 0, mb: 2 }}>
        Choose a date
      </Typography>
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={enGB}>
        <StaticDatePicker
          //orientation="portrait"
          displayStaticWrapperAs="desktop"
          openTo="day"
          value={calendarValue}
          disablePast={true}
          shouldDisableDate={isWeekend}
          onChange={(newValue) => {
            onChange(newValue);
            setCalendarValue(newValue);
          }}
          renderInput={(params) => <TextField {...params} />}
        />
      </LocalizationProvider>
    </Box>
  );
};

export default StaticDatePickerLandscape;
