import React from "react";
import { SvgIcon } from "@mui/material";
export default function Pen(props) {
  return (
    <SvgIcon {...props}>
      <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
      >
        <path d="M493.87,95.6,416.4,18.13a61.88,61.88,0,0,0-87.52,0l-92.45,92.45L136.6,138.79a64,64,0,0,0-43.31,41.35L0,460l52,52,279.86-93.29a64,64,0,0,0,41.35-43.31l28.21-99.83,92.45-92.45a61.88,61.88,0,0,0,0-87.52ZM342.42,366.7a32,32,0,0,1-20.68,21.66l-261.1,87-.7-.7L175.7,358.93A63.28,63.28,0,0,0,208,368a64,64,0,1,0-64-64,63.32,63.32,0,0,0,9.07,32.31L37.32,452.06l-.71-.7,87-261.1a32,32,0,0,1,21.66-20.68l99.83-28.21,1.25-.36L371,265.61l-.35,1.25L342.42,366.7ZM176,304a32,32,0,1,1,32,32A32,32,0,0,1,176,304ZM471.25,160.49l-80.06,80.06L271.44,120.82l80.07-80.06a29.88,29.88,0,0,1,42.27,0l77.47,77.47A29.92,29.92,0,0,1,471.25,160.49Z" />
      </svg>
    </SvgIcon>
  );
}
