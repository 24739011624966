import * as React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { List, ListItem, ListItemIcon, ListItemText } from "@mui/material";

import Home from "../icons/Home";
import Pen from "../icons/Pen";
import ManageUsers from "../icons/ManageUsers";
import Policies from "../icons/Policies";
import PolicyNotifications from "../icons/PolicyNotifications";
import News from "../icons/News";
import Log from "../icons/Log";
import Claims from "../icons/claims";
import QuestionCircle from "../icons/QuestionCircle";
let listSize = "0.9rem";

export default function MainListItems({ handleOpenResources, open }) {
  const location = useLocation();
  const [activeMenu, setActiveMenu] = React.useState(0);
  const [selectedIndex, setSelectedIndex] = React.useState(null);

  const itemMenus = [
    {
      index: 0,
      selected:
        selectedIndex !== 6 && location.pathname.includes("my-dashboard")
          ? true
          : false,
      label: "My Dashboard",
      color: "primary",
      icon: <Home />,
      url: "/my-dashboard",
    },
    {
      index: 1,
      selected:
        selectedIndex !== 6 && location.pathname.includes("application")
          ? true
          : false,
      label: "My Applications",
      color: "primary",
      icon: <Pen />,
      url: "/my-applications",
    },
    {
      index: 2,
      label: "My Policies",
      selected:
        selectedIndex !== 6 &&
        (location.pathname.includes("policies") ||
          location.pathname.includes("policy"))
          ? true
          : false,
      color: "primary",
      icon: <Policies />,
      url: "/my-policies",
    },

    // {
    // 	index: 3,
    // 	label: 'Notify you of a circumstance or claim',
    // 	selected: location.pathname.includes('claim-notification') ? true : false,
    // 	color: 'primary',
    // 	icon: <PolicyNotifications />,
    // 	url: '/claim-notification'
    // },
    // {
    // 	index: 4,
    // 	selected: location.pathname.includes('change-policy') ? true : false,
    // 	label: 'Make  a change to my policy',
    // 	color: 'primary',
    // 	icon: <PolicyNotifications />,
    // 	url: '/change-policy'
    // },
    {
      index: 5,
      selected:
        selectedIndex !== 6 && location.pathname.includes("claim")
          ? true
          : false,
      label: "My Claims",
      color: "primary",
      icon: <Claims />,
      url: "/my-claims",
    },
    {
      index: 4,
      selected:
        selectedIndex !== 6 && location.pathname.includes("news")
          ? true
          : false,
      label: "News",
      color: "primary",
      icon: <News />,
      url: "/news",
    },
    {
      index: 5,
      selected:
        selectedIndex !== 6 && location.pathname.includes("manage-users")
          ? true
          : false,
      label: "Manage Users",
      color: "primary",
      icon: <ManageUsers />,
      url: "/manage-users",
    },
    {
      index: 6,
      selected: selectedIndex === 6 ? true : false,
      label: "Resources",
      color: "primary",
      icon: <QuestionCircle />,
      url: "#",
    },
  ];

  // const [ selected, setSelected ] = React.useState(false);
  // const [ selectedIndex, setSelectedIndex ] = React.useState(1);

  // const handleClick = (path) => {
  // 	//onsole.log(path);
  // 	history.push(path);
  // 	setSelected('true');
  // 	setSelectedIndex(index);
  // };
  const history = useHistory();

  //const [ anchorEl, setAnchorEl ] = React.useState(null);

  // const handleClickListItem = (event) => {
  // 	setAnchorEl(event.currentTarget);
  // };

  const handleMenuItemClick = (itemMenu) => {
    setSelectedIndex(itemMenu.index);

    if (itemMenu.label === "Resources") {
      handleOpenResources();
    } else {
      //setAnchorEl(null);

      history.push(itemMenu.url);
    }
  };

  const handleClose = () => {
    //setAnchorEl(null);
  };
  // <List anchorEl={anchorEl} keepMounted open={true} onClose={handleClose} elevation="0" sx={{ mt: 6 }}>

  return (
    <List open={true} onClose={handleClose} elevation="0" sx={{ mt: 6 }}>
      {itemMenus.map((itemMenu, index) => {
        return (
          <ListItem
            key={index}
            selected={itemMenu.selected}
            onClick={() => handleMenuItemClick(itemMenu)}
          >
            <ListItemIcon
              sx={{ color: itemMenu.selected ? "#9308FF" : "#000000" }}
            >
              {itemMenu.icon}
            </ListItemIcon>

            <ListItemText
              sx={{ fontSize: listSize, textWrap: "wrap" }}
              color={itemMenu.color}
              primaryTypographyProps={{ style: { whiteSpace: "normal" } }}
            >
              {open ? itemMenu.label : <span>&nbsp;</span>}
            </ListItemText>
          </ListItem>
        );
      })}
    </List>
  );

  // return (
  // 	<List>
  // 		{itemMenus.map((itemMenu, index) => {
  // 			return (
  // 				<ListItem
  // 					selected={index === selectedIndex}
  // 					disableRipple
  // 					key={index}
  // 					button
  // 					sx={{ paddingBottom: listSpacing }}
  // 					component={NavLink}
  // 					to={itemMenu.url}
  // 					keepMounted
  // 				>
  // 					<ListItemIcon>{itemMenu.icon}</ListItemIcon>
  // 					<ListItemText
  // 						sx={{ fontSize: listSize }}
  // 						disableTypography
  // 						primary={itemMenu.label}
  // 						color={itemMenu.color}
  // 						onClick={() => handleClick(itemMenu.url)}
  // 					/>
  // 				</ListItem>
  // 			);
  // 		})}
  // 	</List>
  // );
}

// export const secondaryListItems = (
//   <div>
//     <ListSubheader inset>Saved reports</ListSubheader>
//     <ListItem button>
//       <ListItemIcon>
//         <AssignmentIcon />
//       </ListItemIcon>
//       <ListItemText primary="Current month" />
//     </ListItem>
//     <ListItem button>
//       <ListItemIcon>
//         <AssignmentIcon />
//       </ListItemIcon>
//       <ListItemText primary="Last quarter" />
//     </ListItem>
//     <ListItem button>
//       <ListItemIcon>
//         <AssignmentIcon />
//       </ListItemIcon>
//       <ListItemText primary="Year-end sale" />
//     </ListItem>
//   </div>
// );
