import React from "react";
import { FormattedMessage } from "react-intl";
const initialState = {
  isPageLoading: false,
  showAlert: false,
  alertType: "",
  alertMessage: "",
};

function authReducer(state = initialState, action) {
  switch (action.type) {
    case "SHOW_PAGE_LOADER":
      return {
        ...state,
        isPageLoading: true,
      };
    case "HIDE_PAGE_LOADER":
      return {
        ...state,
        isPageLoading: false,
      };
    case "SHOW_ALERT":
      return {
        ...state,
        showAlert: true,
        alertType:
          action.payload.type !== undefined
            ? action.payload.type
            : action.payload.status || action.payload.success
            ? "success"
            : "error",
        alertMessage:
          typeof action.payload?.data?.message == "string" ? (
            action?.payload?.data?.message
          ) : (
            <FormattedMessage id="global.error.msg" />
          ),
      };
    case "HIDE_ALERT":
      return {
        ...state,
        showAlert: false,
        alertType: "",
        alertMessage: "",
      };

    default:
      return state;
  }
}

export default authReducer;
