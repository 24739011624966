import React from "react";
import { Box } from "@mui/material";
import { useHistory } from "react-router-dom";

export default function Index(props) {
  let imageWidth = 0;

  if (props.width) {
    imageWidth = props.width;
  } else {
    imageWidth = "100%";
  }
  const userData = JSON.parse(sessionStorage?.getItem("loggedUserData"));
  const partyLogo = userData?.user_data?.party_business?.company_logo?.document;
  const history = useHistory();
  return (
    <Box
      onClick={() => history.push("/my-dashboard")}
      sx={{ width: imageWidth }}
    >
      <Box
        component="img"
        src={partyLogo}
        sx={{ padding: "0px", width: "100%", cursor: "pointer" }}
        elevation={0}
      />
    </Box>
  );
}
