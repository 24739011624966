import * as React from "react";
import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import ContactBox from "./ContactBox";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import LogoEmblem from "./LogoEmblem";
import LogoutIcon from "@mui/icons-material/Logout";
import { IconButton, Link, Tooltip } from "@mui/material";
import { logout } from "../../../actions/global";
import { useDispatch } from "react-redux";
import CustomDrawer from "./customDrawer";
import ApplicationResources from "./applicationResources";
import { showResources } from "../../../actions/insurance";

const drawerWidth = 100;

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    backgroundColor: "#f2f0f7",
    borderRight: "white",
    boxShadow: "5px 5px 24px -10px #f2f0f7",
    position: "relative",
    whiteSpace: "nowrap",
    overflow: "visible",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "none",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(11),
      },
    }),
  },
}));

const Sidebar = () => {
  // const [open] = React.useState(true);
  const dispatch = useDispatch();

  const [openResources, setOpenResources] = React.useState(false);
  const handleOpenResources = () => {
    setOpenResources(true);
  };

  React.useEffect(() => {
    dispatch(showResources(openResources));
  }, [openResources]);

  return (
    <Drawer elevation={3} variant="permanent" open={true}>
      <Box
        sx={{
          height: "100vh",
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box sx={{ padding: 3 }}>
          {/* <Box
            sx={{ display: "flex", width: "100%", justifyContent: "end" }}
          ></Box> */}
          {<LogoEmblem sx={{ padding: "20px" }} />}
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
            alignItems: "center",
            paddingBottom: "20px",
            gap: 3,
          }}
        >
          <IconButton onClick={handleOpenResources}>
            <Tooltip title="Application Resources">
              <HelpOutlineIcon
                sx={{ fill: "#9308FF", width: 30, height: 30 }}
              />
            </Tooltip>
          </IconButton>
          <ContactBox fromApplication={true} />
          <IconButton>
            <Link
              className="logout-btn"
              style={{ position: "", top: "650px", left: "42px" }}
              underline="none"
              onClick={() => dispatch(logout())}
            >
              <LogoutIcon />
            </Link>
          </IconButton>
        </Box>
        {openResources && (
          <CustomDrawer
            fromFirstTime={true}
            sx={{ padding: "20px" }}
            open={openResources}
            setOpen={setOpenResources}
          >
            <ApplicationResources />
          </CustomDrawer>
        )}
      </Box>
    </Drawer>
  );
};

export default Sidebar;
