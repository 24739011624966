import React from "react";
import { useTheme } from "@mui/material/styles";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
} from "@mui/material";

import IconLabelButton from "./IconLabelButton";

const Transition = React.forwardRef(function Transition(props, ref) {
  const themed = useTheme();
  return (
    <Slide
      timeout={{ enter: 2000, exit: themed.transitions.duration.leavingScreen }}
      easing={{
        enter: themed.transitions.easing.easeInOut,
        exit: themed.transitions.easing.easeInOut,
      }}
      in
      direction="up"
      ref={ref}
      {...props}
    />
  );
});

export default function Modal(props) {
  const handleClose = () => {
    props.setOpen(false);
  };

  return (
    <div>
      <Dialog
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: props.maxWidth, // Set your width here
            },
          },
        }}
        open={props.open}
        TransitionComponent={Transition}
        transitionDuration={{ enter: 1000, exit: 700 }}
        // TransitionProp={{duration:{ enter: 2000, exit: theme.transitions.duration.leavingScreen},easing:{enter: theme.transitions.easing.easeInOut, exit: theme.transitions.easing.easeInOut}}}
        // keepMounted
        fullWidth={true}
        // maxWidth="sm"
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        style={{ padding: "30px" }}
      >
        <DialogTitle sx={{ textAlign: "center" }}>
          {props.dialogTitle}
        </DialogTitle>
        <DialogContent>{props.dialogContent}</DialogContent>
        <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
          {props?.hideBottomClose ? (
            <></>
          ) : (
            <IconLabelButton
              variant="text"
              disableRipple={true}
              mylabel="Cancel"
              iconType="close"
              iconPosition="start"
              onClick={handleClose}
            />
          )}

          {props.fromContactBox && props.times && (
            <IconLabelButton
              iconPosition="end"
              variant="contained"
              mylabel="Confirm"
              onClick={props.handleConfirm}
              iconType={props?.isLoading ? "loading" : "chevron"}
            />
          )}
          {!props.fromContactBox && props?.handleConfirm && (
            <IconLabelButton
              iconPosition="end"
              variant="contained"
              mylabel="Confirm"
              onClick={props.handleConfirm}
              iconType={props?.isLoading ? "loading" : "chevron"}
            />
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
