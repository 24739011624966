import React from 'react';
import { SvgIcon } from '@mui/material';
export default function EditingShared(props) {
	return (
		<SvgIcon {...props} viewBox="0 0 590 378.11">
			<path d="M64.82,333.41a19.6,19.6,0,1,1-19.6-19.6A19.6,19.6,0,0,1,64.82,333.41Zm56,0a19.6,19.6,0,1,1-19.6-19.6A19.61,19.61,0,0,1,120.82,333.41Zm16.8,0A19.6,19.6,0,1,1,157.21,353,19.61,19.61,0,0,1,137.62,333.41Z" />
			<path d="M567.42,172.5l-17.11-17.83a28.35,28.35,0,0,0-41.27,0L402.32,265.9a41.09,41.09,0,0,0-10.64,20.73l-10.13,52.83c-.93,4.8,2.66,9.11,6.61,9.11a7.31,7.31,0,0,0,1.46-.15l50.68-10.56a38.47,38.47,0,0,0,19.88-11.1L566.9,215.53A30.79,30.79,0,0,0,567.42,172.5ZM447,311.88a19.14,19.14,0,0,1-9.94,5.54l-32.81,7.39,6.56-34.18a20.54,20.54,0,0,1,5.32-10.37l69.5-72.45L516.46,240ZM553.67,201.22l-23.48,24.47-30.86-32.17L522.81,169a9.44,9.44,0,0,1,13.75,0l17.11,17.84a10.16,10.16,0,0,1,2.86,7.11A10.39,10.39,0,0,1,553.67,201.22Zm-231.18-14.9c43,0,77.83-36.32,77.83-81.12s-34.84-81.13-77.83-81.13c-42.44,0-77.28,36.32-77.28,81.13S280.05,186.32,322.49,186.32Zm0-142c32.19,0,58.37,27.29,58.37,60.85S354.68,166,322.49,166s-58.37-27.3-58.37-60.84S290.87,44.35,322.49,44.35Zm30,283.94H207.91a1.67,1.67,0,0,1-1.62-1.71c0-49.37,38.55-89.55,85.92-89.55h61.6a80.34,80.34,0,0,1,32.73,7.14c3.94,1.75,8,.61,11.54-2.54,4.65-4.85,3.28-13.21-2.76-15.93a102.68,102.68,0,0,0-41.45-9H292.21c-58.23,0-105.45,49.19-105.38,109.9,0,12.05,9.51,21.93,21.08,21.93H352.57a9.61,9.61,0,0,0,9.4-9.8v-.67C361.41,332.66,357.76,328.29,352.53,328.29Z" />
		</SvgIcon>
	);
}
