import { Box, Skeleton, Typography } from "@mui/material";
import Accordion from "./accordion";
import SimpleDialog from "../../../../utils/simpleModal";
import { useState } from "react";
import { baseURL } from "../../../../config";
import { hasHttps } from "../../../../utils/checkImageUrl";

const Video = ({ loading, applicationResources, systemVideos }) => {
  const accordionItem = applicationResources.videos?.map((item) => {
    return {
      header: `How to complete '${item.section_title}?'`,
      children: (
        <VideoContainer
          includeBaseUrl={true}
          url={item.assitive_video}
          iFrameVideo={item.assitive_video_src}
          title={`How to complete '${item.section_title}?'`}
        />
      ),
    };
  });

  const othersAccordionItem = systemVideos?.map((item) => {
    return {
      header: item?.name,
      children: (
        <VideoContainer
          includeBaseUrl={false}
          url={item.document}
          title={item?.name}
        />
      ),
    };
  });
  const hasOtherVideos = othersAccordionItem?.length > 0;

  const renderLoader = (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 3, mt: 3 }}>
      <Skeleton animation="wave" />
      <Skeleton animation="wave" />
      <Skeleton animation="wave" />
    </Box>
  );

  const renderVideo = (
    <Box
      sx={{
        maxHeight: "72vh",
        overflowY: "auto",
        overflowX: "hidden",
      }}
    >
      <Typography
        sx={{
          mt: 4,
          fontWeight: "bold",
          fontSize: "14px",
          textWrap: "wrap",
          whiteSpace: "normal",
        }}
      >
        Application Videos
      </Typography>

      <Accordion margin={1} accordionItem={accordionItem} />
      {hasOtherVideos && (
        <>
          <Typography sx={{ mt: 4, fontWeight: "bold", fontSize: "14px" }}>
            Other Videos
          </Typography>
          <Accordion margin={1} accordionItem={othersAccordionItem} />
        </>
      )}
    </Box>
  );

  return <div>{loading ? renderLoader : renderVideo}</div>;
};
export default Video;

const VideoContainer = ({ url, title, includeBaseUrl, iFrameVideo }) => {
  const [modalOpen, setModalOpen] = useState(false);
  return (
    <Box sx={{ position: "relative", mt: 2 }}>
      {/* <Box
        onClick={() => {
          setModalOpen(true);
        }}
        sx={{
          position: "absolute",
          zIndex: 40,
          right: 5,
          top: 10,
          cursor: "pointer",
        }}
      >
        <Popup />
      </Box> */}

      {iFrameVideo ? (
        <div
          style={
            {
              // width: "200px",
              // height: "180px",
            }
          }
        >
          <iframe
            title="video"
            src={iFrameVideo}
            loading="lazy"
            style={{
              border: 0,
              height: "100px",
              width: "100%",
            }}
            allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
            allowFullScreen={true}
          ></iframe>
        </div>
      ) : (
        <video
          autoPlay
          muted={false}
          style={{ borderRadius: "18px" }}
          width="100%"
          height="180px"
          controls
        >
          <source type="video/mp4" src={hasHttps(url) ? url : baseURL + url} />
        </video>
      )}

      {/* video modal */}
      <SimpleDialog title={title} open={modalOpen} setOpen={setModalOpen}>
        {iFrameVideo ? (
          <div
            style={{
              height: "100%",
              width: "100%",
            }}
          >
            <iframe
              title="video"
              src={iFrameVideo}
              loading="lazy"
              style={{
                border: 0,
                height: "100%",
                width: "100%",
              }}
              allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
              allowFullScreen={true}
            ></iframe>
          </div>
        ) : (
          <video
            autoPlay
            muted={false}
            style={{ borderRadius: "18px" }}
            width="100%"
            height="100%"
            controls
          >
            <source type="video/mp4" src={url} />
          </video>
        )}
      </SimpleDialog>
    </Box>
  );
};
