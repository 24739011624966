import * as React from "react";
import { Typography, IconButton, Badge, Box, AppBar } from "@mui/material";
import { useSelector } from "react-redux";
import Notification from "../icons/Notification";
import NotificationDrawer from "./NotificationDrawer";
import ProfileAvatar from "../../../utils/ProfileAvatar";
import { useLocation } from "react-router-dom";
export default function Apper() {
  const [notificationDrawer, setNotificationDrawer] = React.useState(false);
  const { parentCompanyDetail, notificationCount } = useSelector(
    (store) => store.global
  );
  const toggleNotificationDrawer = () => {
    setNotificationDrawer(!notificationDrawer);
  };
  const authUserData = JSON.parse(sessionStorage.getItem("user"));
  const companyName = authUserData?.company?.name;
  const location = useLocation();

  const isApplicationIncludesInUrl =
    location?.pathname.includes("/application");
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="fixed"
        sx={{
          background: !isApplicationIncludesInUrl ? "#F2F1F8" : "#fff",
          boxShadow: "21px 21px 40px rgba(0, 0, 0, 0.05)",
        }}
      >
        {" "}
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
            p: 0.5,
            alignItems: "center",
          }}
        >
          {" "}
          <Typography
            component="h1"
            variant="h6"
            color="#000"
            sx={{ mt: 0.5, mr: 2 }}
          >
            {" "}
            {companyName}{" "}
          </Typography>{" "}
          {/* <IconButton
            color="black"
            onClick={toggleNotificationDrawer}
            sx={{ mr: 2, width: "50px" }}
          >
            <Badge
              variant={
                notificationCount && notificationCount.unread !== 0 ? "dot" : ""
              }
              color="error"
            >
              <Notification />
            </Badge>
          </IconButton> */}
          <Typography component="div" sx={{ mr: 2 }}>
            {" "}
            <ProfileAvatar />{" "}
          </Typography>{" "}
        </Box>{" "}
        <NotificationDrawer
          drawerState={notificationDrawer}
          toggleNotificationDrawer={toggleNotificationDrawer}
        />{" "}
      </AppBar>{" "}
    </Box>
  );
}
