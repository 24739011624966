import { SvgIcon } from "@mui/material";

export default function Claims() {
  return (
    <SvgIcon>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        // width="1em"
        // height="1em"
        preserveAspectRatio="xMidYMid meet"
        viewBox="0 0 256 256"
      >
        <path
          fill="currentColor"
          d="M219.6 40.8a8.2 8.2 0 0 0-8.4.8c-28.3 21.2-52.3 10.9-80-1s-60.3-25.8-96 1h-.1l-.4.3l-.2.2l-.3.3l-.3.3l-.2.3l-.3.3c0 .1-.1.2-.2.3l-.2.4c0 .1-.1.2-.1.3a.8.8 0 0 0-.2.4c-.1.1-.1.2-.2.4s-.1.2-.1.3s-.1.3-.1.4s-.1.2-.1.3s-.1.3-.1.4v.5c0 .1-.1.3-.1.4V216a8 8 0 0 0 16 0v-43.9c26.9-18.1 50.1-8.2 76.8 3.3c16.3 6.9 33.8 14.4 52.6 14.4c13.8 0 28.3-4 43.4-15.4a8.1 8.1 0 0 0 3.2-6.4V48a8.2 8.2 0 0 0-4.4-7.2ZM208 163.9c-26.9 18.1-50.1 8.2-76.8-3.3c-24.9-10.6-52.6-22.5-83.2-7V52.1c26.9-18.1 50.1-8.2 76.8 3.3c24.9 10.6 52.6 22.5 83.2 7Z"
        />
      </svg>
    </SvgIcon>
  );
}
