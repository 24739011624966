const initialState = {
  user_details: {},

  tagList: [],
  tagMembers: [],

  requestsList: [],

  requestsListUsers: [],
  groups: [],
  groupMembers: [],
  groupAdmin: {},
  groupMedia: [],
  groupsbyid: {},
  messages: [],
  uploadFileResponse: {},
  searchChatData: [],
  popupClose: true,
  showAlert: false,
  alertType: "",
  alertMessage: "",
  tagStatus: "",
  //small chat
  allChats: [],
  contactList: [],
  searchResults: [],
  sendRequestResponse: {},
  sentRequestsList: [],
  receivedRequestsList: [],
  respondReqRes: {},
  individualMsgs: [],
  createGroupRes: {},
  exitDeleteGroupRes: {},
  addMemberResponse: {},
  notificationList: [],
  notificationCount:{}
};
export default function (state = initialState, action) {
  switch (action.type) {
    case "SETPOPUP":
      return {
        ...state,
        popupClose: false,
      };
    case "UPDATECONTACTLIST":
      return {
        ...state,
        contactList: action.payload,
      };
    case "UPDATEREQUESTSLIST":
      return {
        ...state,
        requestsList: action.payload,
      };

    case "UPDATETAGS":
      return {
        ...state,
        tags: action.payload,
      };
    case "UPDATEGROUPS":
      var oldgroups = state.groupsbyid ? state.groupsbyid : {};
      var newgroups = action.payload;
      newgroups.forEach((group) => {
        oldgroups[group.groupid] = group;
      });
      return {
        ...state,
        groups: action.payload,
        groupsbyid: oldgroups,
      };
    // case "UPDATEGROUPMESSAGES":
    //   var groupMessages = state.groupMessages ? state.groupMessages : {};
    //   const { messages, groupid } = action.payload;
    //   groupMessages[groupid] = messages;
    //   return {
    //     ...state,
    //     groupMessages: groupMessages,
    //   };

    // case "UPDATEMESSAGES":
    //   var oldMessages = state.messages ? state.messages : {};
    //   const { messages1to1, senderid, receiverid } = action.payload;
    //   var key = senderid + receiverid;
    //   oldMessages[key] = messages1to1;
    //   return {
    //     ...state,
    //     messages: oldMessages,
    //   };

    case "UPDATEUSERDB":
      var data = action.payload;
      var oldDb = state.userdb ? state.userdb : {};
      var results = data.results;
      results.forEach((user) => {
        oldDb[`${user.userkey}`] = user;
      });
      return {
        ...state,
        userdb: oldDb,
      };

    case "UPDATESEARCHRESULTS":
      var search =
        action.payload && action.payload.firstname_suggest__completion;
      var mainArray = search && search[0] && search[0].options;
      return {
        ...state,
        searchResults: mainArray,
      };
    case "UPDATESEARCHMESSAGES":
      return {
        ...state,
        searchMessages: action.payload,
      };

    case "USER_DETAILS":
      var data1 = action.payload;
      var oldDb1 = state.user_details ? state.user_details : {};
      var results1 = data1.results;
      results1.forEach((user) => {
        oldDb1[`${user.userkey}`] = user;
      });
      return {
        ...state,
        user_details: oldDb1,
      };

    case "TAG_LIST":
      return {
        ...state,
        tagList: action.payload,
      };
    case "TAG_MEMBERS":
      return {
        ...state,
        tagMembers: action.payload.results,
      };

    case "RECEIVED_REQUESTS":
      const { requests, requestUsers } = action.payload;
      return {
        ...state,
        requestsList: requests,
        requestsListUsers: requestUsers,
      };

    case "GROUP_LIST":
      return {
        ...state,
        groups: action.payload,
      };

    case "GROUP_MEMBER":
      return {
        ...state,
        groupMembers: action.payload,
      };
    case "GROUP_DATA":
      return {
        ...state,
        groupMedia: action.payload,
      };

    case "GET_INDIVIDUAL_CHAT":
      var oldMessages = state.messages ? state.messages : {};
      const { messages1to1, senderid, receiverid } = action.payload;
      var key = senderid + receiverid;
      oldMessages[key] = messages1to1;
      return {
        ...state,
        messages: oldMessages,
      };

    // case actions.GET_GROUP_CHAT:
    //   var messages = state.messages ? state.messages : {};
    //   const { groupMessages, groupid } = action.payload;
    //   messages[groupid] = groupMessages;
    //   return {
    //     ...state,
    //     messages: messages,
    //   };

    case "UPLOAD_FILE":
      return {
        ...state,
        uploadFileResponse: action.payload,
      };

    case "MANAGE_REQUEST":
      return {
        ...state,
        uploadFileResponse: action.payload,
      };
    case "SEARCH_CHAT_DATA":
      return {
        ...state,
        searchChatData: action.payload.data,
      };
    case "SET_TAG_STATUS":
      return {
        ...state,
        tagStatus: action.payload.data,
      };

    //small chat reducers
    case "All_CHAT_DATA":
      return {
        ...state,
        allChats: action.payload,
      };

    case "CONTACT_DETAILS":
      return {
        ...state,
        contactList: action.payload.data,
      };

    case "SEARCHED_USERS":
      // var search =
      //   action.payload && action.payload.firstname_suggest__completion;
      // var mainArray = search && search[0] && search[0].options;

      return {
        ...state,
        searchResults: action.payload,
      };

    case "SEND_REQUEST":
      return {
        ...state,
        sendRequestResponse: action.payload,
      };
    case "LIST_SEND_REQUEST":
      return {
        ...state,
        sentRequestsList: action.payload,
      };
    case "LIST_RECEIVED_REQUESTS":
      return {
        ...state,
        receivedRequestsList: action.payload,
      };
    case "RESPOND_REQUEST":
      return {
        ...state,
        respondReqRes: action.payload,
      };

    case "GET_USERS_CHAT":
      return {
        ...state,
        individualMsgs: action.payload.data,
      };

    case "GET_GROUP_CHAT":
      return {
        ...state,
        messages: action.payload,
      };
    case "CREATE_GROUP":
      return {
        ...state,
        createGroupRes: action.payload,
      };

    case "EXIT_DELETE_RESPONSE":
      return {
        ...state,
        exitDeleteGroupRes: action.payload,
      };

    case "ADD_GROUP_MEMBERS":
      return {
        ...state,
        addMemberResponse: action.payload,
      };

    case "GET_CHAT_NOTIFICATIONS":
      return {
        ...state,
        notificationList: action.payload.data,
        notificationCount:action.payload.count
      };

    default:
      return state;
  }
}
