import { Box, styled, Tooltip, tooltipClasses } from "@mui/material";

const DescriptionTooltip = ({ children, description }) => {
  const RenderTitle = () => {
    return (
      <Box
        sx={{ p: 1 }}
        dangerouslySetInnerHTML={{ __html: description }}
      ></Box>
    );
  };

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 220,
      fontSize: theme.typography?.pxToRem(12),
      border: "1px solid #dadde9",
    },
  }));
  return (
    <HtmlTooltip placement="top" title={<RenderTitle />}>
      {children}
    </HtmlTooltip>
  );
};
export default DescriptionTooltip;
