import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import store from "./store/index";
import App from "./App";
import { IntlProvider } from "react-intl";
import messages_fe from "./translations/fe.json";
import messages_en from "./translations/en.json";

import reportWebVitals from "./reportWebVitals";
const messages = {
  fe: messages_fe,
  en: messages_en,
};
const language = navigator.language.split(/[-_]/)[0];
ReactDOM.render(
  <IntlProvider locale={language} messages={messages[language]}>
    <Provider store={store}>
      <App />
    </Provider>
  </IntlProvider>,
  document.getElementById("root")
);

reportWebVitals();
