import { Box, Typography } from "@mui/material";
import CustomTabs from "../customTabs";
import Video from "./video";
import Guide from "./guide";
import Faq from "./faq";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  getApplicationResources,
  getOtherGuideResources,
  getOtherVideoResources,
} from "../../../../actions/insurance";

const ApplicationResources = () => {
  const dispatch = useDispatch();

  const productId = useSelector(
    (state) => state.auth?.user?.contact?.products?.[0]?.product_id
  );

  const { applicationResources, resourcesLoading, systemGuides, systemVideos } =
    useSelector((state) => {
      return state.insurance;
    });

  useEffect(() => {
    dispatch(getApplicationResources(productId));
    dispatch(getOtherVideoResources());
    dispatch(getOtherGuideResources());
  }, [dispatch, productId]);

  const tabItems = [
    {
      id: 1,
      header: "Videos",
      component: (
        <Video
          loading={resourcesLoading}
          systemVideos={systemVideos?.data}
          applicationResources={applicationResources?.data ?? []}
        />
      ),
    },
    {
      id: 2,
      header: "Guides",
      component: (
        <Guide
          systemGuides={systemGuides?.data}
          applicationResources={applicationResources}
        />
      ),
    },
    {
      id: 3,
      header: "FAQS",
      component: <Faq applicationResources={applicationResources} />,
    },
  ];

  return (
    <div>
      <Box>
        <Typography
          sx={{ fontWeight: "600", color: "#4C4954", fontSize: "1.5rem" }}
        >
          Resources
        </Typography>
      </Box>
      <Box mt={4}>
        <CustomTabs tabItems={tabItems} />
      </Box>
    </div>
  );
};
export default ApplicationResources;
