import {
  showErrorMessage,
  showSuccessMessage,
} from "../components/presentation/common/snackbar";
import { AvailabilityAPI, CalendarAPI } from "../config";
import { errorMessage, successMessage } from "../utils/responseMessage";

export const getAvailStatus = (id, month, year) => (dispatch) => {
  AvailabilityAPI.get(`/available?userid=${id}&&month=${month}&&year=${year}`)
    .then((response) => {
      dispatch({
        type: "AVAIL_USER_CONTENT",
        payload: response.data.data,
      });
    })
    .catch(function (error) {
      showErrorMessage(errorMessage(error));
    });
};

export const getUsersAvail = (userid, date) => (dispatch) => {
  AvailabilityAPI.get(`/available?userid=${userid}&&date=${date}&&count=true`)
    .then((response) => {
      dispatch({
        type: "AVAIL_GET_MULTI_AVAIL_USER",
        payload: response.data,
      });
    })
    .catch(function (error) {
      showErrorMessage(errorMessage(error));
    });
};

export const getEvent = (user_id) => (dispatch) => {
  CalendarAPI.get("user-events?user_id=" + user_id)
    .then((response) => {
      if (response.data.success) {
        dispatch({
          type: "AVAIL_EVENT_LIST",
          payload: response.data,
        });
      } else {
        showErrorMessage(errorMessage(response));
      }
    })
    .catch(function (error) {
      showErrorMessage(errorMessage(error));
    });
};

export const getQueryType = () => (dispatch) => {
  AvailabilityAPI.get(`/customer-query-type`)
    .then((response) => {
      if (response.status) {
        dispatch({
          type: "GET_QUERY_TYPE",
          payload: response.data,
        });
      }
    })
    .catch((err) => {
      showErrorMessage(errorMessage(err));
    });
};

export const getAvailableSlot =
  ({ current_user, code, date, user_id, duration }) =>
  (dispatch) => {
    AvailabilityAPI.get(
      `/available-slot?current_user=${current_user}&code=${code}&date=${date}&user_id=${user_id}&duration=${duration}`
    )
      .then((response) => {
        if (response.status) {
          dispatch({
            type: "GET_AVAILABLE_SLOT",
            payload: response.data,
          });
        }
      })
      .catch((err) => {
        showErrorMessage(errorMessage(err));
        dispatch({
          type: "GET_AVAILABLE_SLOT",
          payload: [],
        });
      });
  };

export const requestSlot =
  ({ current_user, payload, code, setOpenPhone }) =>
  (dispatch) => {
    dispatch({
      type: "REQUEST_LOADING",
      payload: true,
    });
    AvailabilityAPI.post(
      `/requested-slot?current_user=${current_user}&code=${code}`,
      payload
    )
      .then((response) => {
        if (response.status) {
          showSuccessMessage(successMessage(response));
          dispatch({
            type: "REQUEST_LOADING",
            payload: false,
          });
          setOpenPhone?.(false);
        } else {
          showErrorMessage(errorMessage(response));
          dispatch({
            type: "REQUEST_LOADING",
            payload: false,
          });
        }
      })
      .catch((err) => {
        showErrorMessage(errorMessage(err));
      });
  };
