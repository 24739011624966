import API, { NotesAPI, NotificationAPI } from "../config";
const userData =
  sessionStorage.getItem("loggedUserData") &&
  JSON.parse(sessionStorage.getItem("loggedUserData")).user_data;
export const logout = () => (dispatch) => {
  dispatch({
    type: "LOGOUT_SUCCESS",
  });
  window.location.reload();
  dispatch({
    type: "HIDE_PAGE_LOADER",
    payload: null,
  });
};

export const openChatWindow = (flag, selectedView) => (dispatch) => {
  dispatch({
    type: "OPEN_CHAT_SUCESS",
    payload: { flag, selectedView },
  });
};
export const setUserOnlineStatus = (data) => (dispatch) => {
  dispatch({
    type: "USER_ON_STATUS",
    payload: data,
  });
};
export const setChatMsgStatus = (data) => (dispatch) => {
  dispatch({
    type: "CHAT_MSG_STAT",
    payload: data,
  });
};
export const setDiffApiCallOnSocketHit = (data) => (dispatch) => {
  dispatch({
    type: "API_CALL_ON_SOCKET_HIT",
    payload: data,
  });
};
export const getStickyNotes = (user_id) => (dispatch) => {
  // showPageLoader(dispatch);

  NotesAPI.get("notes/user?user_id=" + user_id)
    .then((response) => {
      dispatch({
        type: "GET_STICKY_NOTES",
        payload: response.data,
      });
    })
    .catch(function (error) {
      // dispatch(showSuccessError(response.data));
    })
    .finally(function () {
      // hidePageLoader(dispatch);
    });
};

export const saveStickyNotes = (data, host) => (dispatch) => {
  // showPageLoader(dispatch);

  NotesAPI.post("notes/user", data)
    .then((response) => {
      if (response.data.success) {
        dispatch({
          type: "SAVE_STICKY_NOTES",
          // payload: response.data,
        });
      } else {
        // showSuccessError(dispatch, response.data);
      }
    })
    .catch(function (error) {
      // dispatch(showSuccessError(response.data));
    })
    .finally(function () {
      // hidePageLoader(dispatch);
    });
};

export const getNotifications = (user_id) => (dispatch) => {
  // showPageLoader(dispatch);
  NotificationAPI.get("/?receiver=" + user_id)
    .then((response) => {
      // if (response.data.success) {
      dispatch({
        type: "GET_NOTIFICATIONS",
        payload: response.data,
      });
      // } else {
      //   // showSuccessError(dispatch, response.data);
      // }
    })
    .catch(function (error) {
      // dispatch(showSuccessError(response.data));
    })
    .finally(function () {
      // hidePageLoader(dispatch);
    });
};

export const readNotifications = (notification_id) => (dispatch) => {
  // showPageLoader(dispatch);
  let formData = new FormData();
  formData.append("seen", true);
  NotificationAPI.put("/" + notification_id + "/", formData)
    .then((response) => {
      if (response.data.status) {
        dispatch({
          type: "READ_NOTIFICATION",
          payload: response.data,
        });
      } else {
        // dispatch(showSuccessError(response.data));
      }
    })
    .catch(function (error) {
      // dispatch(showSuccessError(error));
    })
    .finally(function () {
      // hidePageLoader(dispatch);
    });
};
export const searchGlobally = (search) => (dispatch) => {
  // showPageLoader(dispatch);

  API.get(`/home/search?search_key=${search}&current_user=${userData?.id}`)
    .then((response) => {
      // if (response.data.success) {
      dispatch({
        type: "SEARCH_DATA",
        payload: response.data,
      });
      // } else {
      //   // showSuccessError(dispatch, response.data);
      // }
    })
    .catch(function (error) {
      // dispatch(showSuccessError(response.data));
    })
    .finally(function () {
      // hidePageLoader(dispatch);
    });
};

export const getUserActivity = (email) => (dispatch) => {
  // showPageLoader(dispatch);

  API.get(`trackuser/activity?user_email=${email}&current_user=${userData?.id}`)
    .then((response) => {
      if (response.data) {
        dispatch({
          type: "USER_ACTIVITY",
          payload: response.data,
        });
      } else {
        // showSuccessError(dispatch, response.data);
      }
    })
    .catch(function (error) {
      // dispatch(showSuccessError(response.data));
    })
    .finally(function () {
      // hidePageLoader(dispatch);
    });
};

export const getBusinessInvites = (email) => (dispatch) => {
  // showPageLoader(dispatch);

  API.get(`party/person/business?email=${email}&current_user=${userData?.id}`)
    .then((response) => {
      if (response.data.status) {
        dispatch({
          type: "BUSINESS_INVITIES",
          payload: response.data.data,
        });
      } else {
        // showSuccessError(dispatch, response.data);
      }
    })
    .catch(function (error) {
      // dispatch(showSuccessError(response.data));
    })
    .finally(function () {
      // hidePageLoader(dispatch);
    });
};

export const getParentCompany = (email) => (dispatch) => {
  // showPageLoader(dispatch);

  API.get(`external/party/person/detail/email?email=${email}`)
    .then((response) => {
      if (response.data.status) {
        dispatch({
          type: "PARENT_COMPANY",
          payload: response.data.data,
        });
      } else {
        // showSuccessError(dispatch, response.data);
      }
    })
    .catch(function (error) {
      // dispatch(showSuccessError(response.data));
    })
    .finally(function () {
      // hidePageLoader(dispatch);
    });
};

export const updateNotification = (payload) => (dispatch) => {
  NotificationAPI.patch(`/`, payload)
    .then((response) => {
      if (response.data.status) {
        dispatch({
          type: "REAL_ALL_NOTIFICATION",
          payload: response.data.data,
        });
        NotificationAPI.get("/?receiver=" + payload?.user).then(
          (updateResponse) => {
            dispatch({
              type: "GET_NOTIFICATIONS",
              payload: updateResponse.data,
            });
          }
        );
      } else {
        //
      }
    })
    .catch(function (error) {})
    .finally(function () {});
};
