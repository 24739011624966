import React from "react";
import { Box } from "@mui/material";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const Logo = (props) => {
  let imageWidth = 0;
  let maxWidth = 0;

  if (props.width) {
    imageWidth = props.width;
  } else {
    imageWidth = "100%";
  }

  if (props.maxWidth) {
    maxWidth = props.maxWidth;
  } else {
    maxWidth = "300px";
  }

  const history = useHistory();

  const userData = JSON.parse(sessionStorage?.getItem("loggedUserData"));
  const partyLogo = userData?.user_data?.party_business?.company_logo?.document;
  return (
    <Box
      onClick={() => history.push("/my-dashboard")}
      sx={{ width: imageWidth, maxWidth: maxWidth, cursor: "pointer" }}
    >
      <Box
        component="img"
        src={partyLogo}
        alt=""
        sx={{
          width: "100%",
          height: "100px",
          objectFit: "contain",
        }}
        elevation={0}
      />
    </Box>
  );
};

export default Logo;
