import {
  showErrorMessage,
  showSuccessMessage,
} from "../components/presentation/common/snackbar";
import { CrmCompanyAPI, PortalCompanyAPI, userAPI } from "../config";
import { errorMessage, successMessage } from "../utils/responseMessage";

export const getUsers = (companyId, party_id, value) => (dispatch) => {
  CrmCompanyAPI.get(
    `/contacts?company_id=${companyId}&party_business_id=${party_id}&page=${
      value || 1
    }`
  )
    .then((response) => {
      if (response.status === 200) {
        dispatch({
          type: "GET_USERS",
          payload: response.data,
        });
      } else {
        showErrorMessage(errorMessage(response));
      }
    })
    .catch(function (error) {
      showErrorMessage(errorMessage(error));
    });
};

export const getAllUsers = (companyId) => (dispatch) => {
  const getLoading = (loading) => {
    return dispatch({
      type: "GET_USER_LOADING",
      payload: loading,
    });
  };
  getLoading(true);
  userAPI
    .get(`user/user-profile?company_id=${companyId}`)
    .then((response) => {
      if (response.status === 200) {
        dispatch({
          type: "GET_ALL_USERS",
          payload: response.data,
        });
        getLoading(false);
      } else {
        showErrorMessage(errorMessage(response));
        getLoading(false);
      }
    })
    .catch((error) => {
      showErrorMessage(errorMessage(error));
      getLoading(false);
    });
};

export const getUserById = (id) => (dispatch) => {
  userAPI
    .get(`/user/user-profile?invoffuserid=${id}`)
    .then((response) => {
      if (response.status === 200) {
        dispatch({
          type: "GET_USER_BY_ID",
          payload: response.data.data,
        });
      } else {
        showErrorMessage(errorMessage(response));
      }
    })
    .catch(function (error) {
      showErrorMessage(errorMessage(error));
    });
};

export const AddUser = (data, invitationData) => (dispatch) => {
  dispatch({
    type: "ADD_SINGLE_USER_LOADING",
    payload: true,
  });
  PortalCompanyAPI.post(`contacts?create_crm_contact=true`, data)
    .then((response) => {
      if (response.status === 201 || response.status === 200) {
        dispatch({
          type: "ADD_EDIT_USER",
          payload: response.data.data,
        });
        dispatch(
          sendInviteForUser(response.data.data.id, data, {
            ...invitationData,
            created_by: response.data.data?.created_by?.id,
          })
        );
        dispatch({
          type: "ADD_SINGLE_USER_LOADING",
          payload: false,
        });
        showSuccessMessage(successMessage(response));
      } else {
        showErrorMessage(errorMessage(response));
        dispatch({
          type: "ADD_SINGLE_USER_LOADING",
          payload: false,
        });
      }
    })
    .catch(function (error) {
      showErrorMessage(errorMessage(error));
      dispatch({
        type: "ADD_SINGLE_USER_LOADING",
        payload: false,
      });
    });
};

const sendInviteForUser = (user_id, data, invitationData) => (dispatch) => {
  PortalCompanyAPI.post(`/contacts/${user_id}/invite`, invitationData)
    .then((response) => {
      if (response.status === 201 || response.status === 200) {
        dispatch(getAllUsers(data.company));
      } else {
        showErrorMessage(errorMessage(response));
      }
    })
    .catch(function (error) {
      showErrorMessage(errorMessage(error));
    });
};

export const updateSingleUser =
  (data, user_id, history, companyId) => (dispatch) => {
    dispatch({
      type: "UPDATE_SINGLE_USER_LOADING",
      payload: true,
    });
    userAPI
      .put(`/user/user-profile/${user_id}`, data)
      .then((response) => {
        if (response?.data?.status) {
          history?.push("/manage-users");
          showSuccessMessage(successMessage(response));
          companyId && dispatch(getAllUsers(companyId));
          dispatch({
            type: "UPDATE_SINGLE_USER_LOADING",
            payload: false,
          });
        } else {
          showErrorMessage(errorMessage(response));
          dispatch({
            type: "UPDATE_SINGLE_USER_LOADING",
            payload: false,
          });
        }
      })
      .catch(function (error) {
        showErrorMessage(errorMessage(error));
        dispatch({
          type: "UPDATE_SINGLE_USER_LOADING",
          payload: false,
        });
      });
  };

export const deactivateUser = (id, companyId) => (dispatch) => {
  userAPI
    .delete(`/user/user-profile/${id}`)
    .then((response) => {
      if (response.data.status) {
        dispatch({
          type: "DEACTIVATE_USER",
          payload: response.data,
        });
        showSuccessMessage(successMessage(response));
        dispatch(getAllUsers(companyId));
      } else {
        showErrorMessage(errorMessage(response));
      }
    })
    .catch(function (error) {
      showSuccessMessage(errorMessage(error));
    });
};

export const getRoles = (business_id) => (dispatch) => {
  userAPI
    .get(`/user/role-permissions?business_id=${business_id}`)
    .then((response) => {
      if (response.data.status) {
        dispatch({
          type: "GET_ROLES",
          payload: response.data.data,
        });
      } else {
        showErrorMessage(errorMessage(response));
      }
    })
    .catch(function (error) {
      showErrorMessage(errorMessage(error));
    });
};
