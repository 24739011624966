const initialState = {
  allClaimsLoading: false,
  allClaims: [],
  addClaimsLoading: false,
  singleClaims: {},
  singleClaimsLoading: false,
  claimsReasons: [],
  productProvider: [],
};

const claimsReducer = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case "GET_ALL_CLAIMS_LOADING":
      return {
        ...state,
        allClaimsLoading: payload,
      };
    case "GET_ALL_CLAIMS":
      return {
        ...state,
        allClaims: payload,
      };

    case "ADD_CLAIMS_LOADING":
      return {
        ...state,
        addClaimsLoading: payload,
      };

    case "GET_CLAIMS_REASONS":
      return {
        ...state,
        claimsReasons: payload,
      };
    case "GET_SINGLE_CLAIM":
      return {
        ...state,
        singleClaims: payload,
      };
    case "SINGLE_CLAIM_LOADING":
      return {
        ...state,
        singleClaimsLoading: payload,
      };
    case "GET_PRODUCT_PROVIDER":
      return {
        ...state,
        productProvider: payload,
      };

    default:
      return state;
  }
};

export default claimsReducer;
