import React from 'react';
import { SvgIcon } from '@mui/material';
export default function ChevronDown(props) {
	return (
		<SvgIcon {...props} viewBox="0 0 448 512">
			<path
				fill="currentColor"
				d="M443.5 162.6l-7.1-7.1c-4.7-4.7-12.3-4.7-17 0L224 351 28.5 155.5c-4.7-4.7-12.3-4.7-17 0l-7.1 7.1c-4.7 4.7-4.7 12.3 0 17l211 211.1c4.7 4.7 12.3 4.7 17 0l211-211.1c4.8-4.7 4.8-12.3.1-17z"
			/>
		</SvgIcon>
	);
}
