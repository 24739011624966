export const errorMessage = (err) => {
  return (
    err?.response?.data?.message ??
    err?.response?.data?.message_string ??
    err?.response?.data?.detail ??
    err?.data?.message ??
    err?.response?.data?.error ??
    err?.data?.message_string
  );
};

export const successMessage = (response) => {
  return (
    response?.data?.message ??
    response?.data?.message_string ??
    response?.data?.description
  );
};
