import React, { useEffect, useState } from "react";
import { CssBaseline, Container, Box } from "@mui/material";
import SidebarApplication from "../common/SidebarApplication";
import RingsProgress from "../../../utils/RingsProgress";
import { useLocation, useParams } from "react-router-dom";
import Apper from "../common/Appbar";
import { useSelector } from "react-redux";

const ApplicationLayout = ({ children }) => {
  const { applicationId } = useParams();

  const location = useLocation();
  const { isOpenResources } = useSelector((state) => state.insurance);
  const [openResources, setOpenResources] = useState(isOpenResources);

  useEffect(() => {
    setOpenResources(isOpenResources);
  }, [isOpenResources]);
  const isSubmittedIncludesInUrl = location?.pathname.includes("/submitted");
  const showRing = applicationId && !isSubmittedIncludesInUrl;
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <SidebarApplication />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          height: openResources ? "100vh" : "auto",
          overflow: "auto",
        }}
      >
        {showRing && <RingsProgress />}
        <Apper />
        <Box paddingTop={0} paddingLeft={7} paddingRight={7} paddingBottom={7}>
          <Container maxWidth="lg" sx={{ mt: 8, mb: 4 }}>
            {children}
          </Container>
        </Box>
      </Box>
    </Box>
  );
};
export default ApplicationLayout;
