import {
  showErrorMessage,
  showSuccessMessage,
} from "../components/presentation/common/snackbar";
import { CoreAPI, loginAuth, storageApi, userAPI } from "../config";
import { errorMessage } from "../utils/responseMessage";

function showPageLoader(dispatch) {
  dispatch({
    type: "SHOW_PAGE_LOADER",
    payload: null,
  });
}

function hidePageLoader(dispatch) {
  dispatch({
    type: "HIDE_PAGE_LOADER",
    payload: null,
  });
}

// LOGIN
export const login = (user, history) => (dispatch) => {
  const setLoading = (payload) => dispatch({ type: "LOGIN_LOADING", payload });
  setLoading(true);
  showPageLoader(dispatch);
  loginAuth
    .post("login", user)
    .then((response) => {
      if (response.data.status) {
        const userId = response.data?.user_data?.invoffuserid;
        const redirect = () => {
          history.push({
            pathname: "/my-dashboard",
            state: { fromLogin: true },
          });
        };
        CoreAPI.get(`customer-portal/dashboard?current_user=${userId}`, {
          headers: {
            "content-type": "application/json",
            Accept: "application/json",
            Authorization: `Token ${response.data?.token?.token}`,
          },
        })
          .then((appResponse) => {
            dispatch({
              type: "GET_DASHBOARD_LOADING",
              payload: false,
            });
            dispatch({
              type: response.data.status ? "LOGIN_SUCCESS" : "LOGIN_FAIL",
              payload: response.data,
            });
            dispatch({
              type: "GET_DASHBOARD_DETAILS",
              payload: appResponse.data,
            });
            const appCount = appResponse?.data?.data?.total_count?.applications;
            sessionStorage.setItem("application_count", appCount);
            if (appCount > 0) {
              redirect();
            } else {
              history.push("/application/welcome");
            }
            setLoading(false);
          })
          .catch(() => {
            redirect();
            window.location.reload();
          });
      } else {
        setLoading(false);
        showErrorMessage(response.data.message);
      }
    })
    .catch((err) => {
      setLoading(false);
      showErrorMessage(errorMessage(err));
    });
};

export const getConsentDocument = () => (dispatch) => {
  try {
    const loggedUserData = JSON.parse(sessionStorage.getItem("loggedUserData"));
    const party_business = loggedUserData?.user_data?.party_business?.id;
    storageApi
      .get(
        `static-docs?business_id=${party_business}&category=data_privacy_policy`
      )
      .then((response) => {
        if (response.data) {
          dispatch({
            type: "GET_CONSENT_DOCUMENT",
            payload: response.data,
          });
        }
      });
  } catch (error) {}
};

export const sendConsent = (payload, setOpen) => (dispatch) => {
  try {
    const loggedUserData = JSON.parse(sessionStorage.getItem("loggedUserData"));
    const userid = loggedUserData?.user_data?.id;
    userAPI.post(`user/${userid}/consents`, payload).then((response) => {
      if (response.data) {
        dispatch({
          type: "SEND_CONSENT_REQ",
          payload: response.data.data,
        });
        setOpen(false);
        sessionStorage.setItem("consent", true);
      }
    });
  } catch (error) {}
};

export const registerUser = (user, history) => (dispatch) => {
  showPageLoader(dispatch);

  loginAuth
    .post("register-user", user)
    .then((response) => {
      if (response.data.status) {
        showSuccessMessage(response.data?.message);
        setTimeout(function () {
          history.push("/login");
        }, 1000);
      } else {
        showErrorMessage(response.data.message);
      }
    })
    .catch(function (error) {
      showErrorMessage(error?.data?.message);
    })
    .finally(function () {
      hidePageLoader(dispatch);
    });
};

export const forgotPassword = (user) => (dispatch) => {
  showPageLoader(dispatch);
  loginAuth
    .post("forgot_password", user)
    .then((response) => {
      showSuccessMessage(response.data?.message);
    })
    .catch((error) => {
      showErrorMessage(error.data?.message);
    })
    .finally(() => {
      hidePageLoader(dispatch);
    });
};

export const updatePassword = (data, history) => (dispatch) => {
  showPageLoader(dispatch);
  const token = sessionStorage.getItem("token");
  if (token !== null) {
    loginAuth.defaults.headers.common["Authorization"] = "Token " + token;
  }
  loginAuth
    .post("change_password", data)
    .then((response) => {
      hidePageLoader(dispatch);

      if (response.data.status) {
        dispatch({
          type: "UPDATE_PASSWORD_SUCCESS",
          payload: response.data,
        });
        history.push("/login");

        showSuccessMessage(response.data?.message);
      } else {
        showErrorMessage(response.data?.message);
      }
    })
    .catch(function (error) {
      showErrorMessage(error?.data?.message);
    })
    .finally(function () {
      hidePageLoader(dispatch);
    });
};

export const setNewPassword = (data) => (dispatch) => {
  showPageLoader(dispatch);
  loginAuth
    .post("set_password", data)
    .then((response) => {
      hidePageLoader(dispatch);
      if (response.data.status) {
        dispatch({
          type: "UPDATE_PASSWORD_SUCCESS",
          payload: response.data,
        });
        showSuccessMessage(response.data?.message);
        setTimeout(function () {
          window.location.href = "/login";
        }, 1000);
      } else {
        showErrorMessage(response.data.message);
      }
    })
    .catch(function (error) {
      showErrorMessage(error.data.message);
    })
    .finally(function () {
      hidePageLoader(dispatch);
    });
};

export const changePasswordInner =
  ({ formData, currentUser }) =>
  (dispatch) => {
    showPageLoader(dispatch);
    loginAuth
      .post(`change_password/login?current_user=${currentUser}`, formData)
      .then((response) => {
        hidePageLoader(dispatch);
        if (response.data.status) {
          showSuccessMessage(response.data?.message);
          sessionStorage.clear();
          window.location.reload();
        } else {
          showErrorMessage(response.data.message);
        }
      })
      .catch(function (error) {
        showErrorMessage(error?.data?.message);
      })
      .finally(function () {
        hidePageLoader(dispatch);
      });
  };

export const changePasswordOuter = (data, history) => (dispatch) => {
  showPageLoader(dispatch);
  loginAuth
    .post("change_password", data)
    .then((response) => {
      hidePageLoader(dispatch);
      if (response.data.status) {
        showSuccessMessage(response.data?.message);

        history.push("/login");
      } else {
        showErrorMessage(response.data?.message);
      }
    })
    .catch(function (error) {
      showErrorMessage(error?.data?.message);
    })
    .finally(function () {
      hidePageLoader(dispatch);
    });
};

export const getUserListQuestions = (res) => (dispatch) => {
  showPageLoader(dispatch);
  loginAuth
    .get("question_for_user", res)
    .then((response) => {
      hidePageLoader(dispatch);
      if (response.data.status === true) {
        dispatch({
          type: "QUESTION_LIST",
          payload: response.data,
        });
      } else {
        //
      }
    })
    .catch(function (error) {
      //
    })
    .finally(function () {
      hidePageLoader(dispatch);
    });
};

export const getRegisteredContactDetails = (contact_id) => (dispatch) => {
  showPageLoader(dispatch);
  loginAuth
    .get("party/person/detail/" + contact_id)
    .then((response) => {
      if (response.data.status === true) {
        dispatch({
          type: "REGISTERED_USER_DATA",
          payload: response.data,
        });
      } else {
        //
      }
    })
    .catch(function (error) {
      //
    })
    .finally(function () {
      //
    });
};

export const verifyActivationKey =
  ({ payload, source }) =>
  (dispatch) => {
    loginAuth
      .post(`verify-activation-key`, payload)
      .then(() => {})
      .catch((err) => showErrorMessage(errorMessage(err)));
  };
