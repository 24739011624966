import { Box, Divider, IconButton, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useState } from "react";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
const Accordion = ({ accordionItem, margin }) => {
  return (
    <div>
      {accordionItem?.map((item, id) => {
        return (
          <Box key={id}>
            <AccordionItems margin={margin} item={item} />
            <Divider mt={1} />
          </Box>
        );
      })}
    </div>
  );
};
export default Accordion;

const AccordionItems = ({ item, margin }) => {
  const [show, setShow] = useState(false);
  const handleShow = () => {
    setShow(!show);
  };
  return (
    <>
      <Box
        onClick={handleShow}
        sx={{
          display: "flex",
          cursor: "pointer",
          justifyContent: "space-between",
          userSelect: "none",
          alignItems: "center",
          mt: margin ?? 3,
          textWrap: "wrap",
          whiteSpace: "normal",
        }}
      >
        <Typography
          sx={{
            fontSize: "13px",
            fontFamily: "unset",
            fontWeight: "500",
            marginBottom: "4px",
            color: "#4C4954",
            whiteSpace: "normal",
          }}
        >
          {item.header}
        </Typography>
        {show ? (
          <IconButton>
            <ExpandLessIcon sx={{ height: 30, width: 30 }} />
          </IconButton>
        ) : (
          <IconButton>
            <ExpandMoreIcon sx={{ height: 30, width: 30 }} />
          </IconButton>
        )}
      </Box>
      {show && (
        <Box sx={{ textWrap: "wrap", whiteSpace: "normal" }}>
          {item.children}
        </Box>
      )}
    </>
  );
};
