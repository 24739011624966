import { letters } from "./constants";

export const getColorProfile = ({ firstName, lastName }) => {
  const firstLetter = firstName?.[0]?.toLocaleLowerCase();
  const letterIndex = letters.findIndex((letter) => letter === firstLetter);

  let randomProfileBg;
  if (letterIndex < 6) {
    randomProfileBg = "#9308FF";
  } else if (letterIndex < 12) {
    randomProfileBg = "#526Eff";
  } else if (letterIndex < 18) {
    randomProfileBg = "#0CDEFF";
  } else {
    randomProfileBg = "#9308FF";
  }

  return randomProfileBg;
};
