const initialState = {
    // update_contact_res: {},
    // timezone_reducer: [],
    // visibility_reducer: [],
  
    // create_contact_reducer: {},
    // contact_reducer: {},
    // my_contacts_reducer: [],
    allContactWithoutPagi:[],
  
    // contact_history_reducer: [],
    // business_types_reducer: [],
    // check_company_domain_reducer: {},
  
    // shared_briefcases_reducer: [],
    // shared_events_reducer: [],
    // users_reducer: [],
    // contact_alrady_exist: {},
  };
  
  export default function (state = initialState, action) {
    switch (action.type) {
    //   case "CREATE_CONTACT":
    //     return {
    //       ...state,
    //       create_contact_reducer: action.payload.data,
    //     };
  
    //   case "UPDATE_CONTACT":
    //     return {
    //       ...state,
    //       update_contact_res: action.payload.data,
    //     };
    //   case "GET_TIMEZONES":
    //     return {
    //       ...state,
    //       timezone_reducer: action.payload.data,
    //     };
  
    //   case "GET_VISIBILITIES":
    //     return {
    //       ...state,
    //       visibility_reducer: action.payload.data,
    //     };
    //   case "GET_CONTACT":
    //     return {
    //       ...state,
    //       contact_reducer: action.payload.data,
    //     };
  
    //   case "GET_MY_CONTACTS":
    //     return {
    //       ...state,
    //       my_contacts_reducer: action.payload.data,
    //     };
      case "ALL_CONTACT_WITHOUT_PAGIN":
        return {
          ...state,
          allContactWithoutPagi: action.payload,
        };
    //   case "CONTACT_HISTORY":
    //     return {
    //       ...state,
    //       contact_history_reducer: action.payload.data,
    //     };
  
    //   case "REF_BUSINESS_TYPES":
    //     return {
    //       ...state,
    //       business_types_reducer: action.payload.data,
    //     };
  
    //   case "CHECK_COMPANY_BY_DOMAIN":
    //     return {
    //       ...state,
    //       check_company_domain_reducer: action.payload.data,
    //     };
  
    //   case "SHARED_BRIEFCASES":
    //     return {
    //       ...state,
    //       shared_briefcases_reducer: action.payload,
    //     };
  
    //   case "SHARED_EVENTS":
    //     return {
    //       ...state,
    //       shared_events_reducer: action.payload.data,
    //     };
  
    //   case "GET_CONTACT_CASES":
    //     return {
    //       ...state,
    //       contacts_cases_reducer: action.payload.data,
    //     };
  
    //   case "GET_ALL_REGISTER_USER":
    //     return {
    //       ...state,
    //       users_reducer: action.payload.data,
    //     };
  
    //   case "IS_CONTACT_ALREADY_EXIST":
    //     return {
    //       ...state,
    //       contact_alrady_exist: action.payload,
    //     };
      default:
        return state;
    }
  }
  