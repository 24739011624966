import React from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { errorMessage } from "../../../../utils/constants";
import styled from "styled-components";

const Snackbar = () => {
  return (
    <Wrapper>
      <ToastContainer
        position="bottom-right"
        autoClose={3000} // Auto-close the notification after 3 seconds
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Wrapper>
  );
};

export const showSuccessMessage = (message) => {
  toast.success(message, {
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

export const showErrorMessage = (message = errorMessage) => {
  toast.error(message, {
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

export default Snackbar;

const Wrapper = styled.div`
  .Toastify__toast-body {
    white-space: pre-line;
  }
  .Toastify__toast {
    min-width: 350px;
  }
`;
