import { Box, Button } from "@mui/material";
import { useState } from "react";

const CustomTabs = ({ tabItems }) => {
  const [activeTab, setActiveTab] = useState(1);

  const handleActive = (tabId) => {
    setActiveTab(tabId);
  };

  return (
    <Box>
      {/* tab herader */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          borderBottom: "1px solid gray",
        }}
      >
        {tabItems.map((item, index) => {
          return (
            <>
              <Box
                key={index}
                sx={{
                  borderBottom:
                    activeTab === item.id ? "2px solid #9308FF" : "",
                }}
              >
                <Button onClick={() => handleActive(item.id)}>
                  {item.header}
                </Button>
              </Box>
            </>
          );
        })}
      </Box>
      {/* tab body component */}
      {tabItems.find((item) => item.id === activeTab)?.component}
    </Box>
  );
};
export default CustomTabs;
