import moment from "moment";
import {
  CoreAPI,
  InsurecoreAPI,
  PortalCompanyAPI,
  storageApi,
} from "../config";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../components/presentation/common/snackbar";
import { errorMessage, successMessage } from "../utils/responseMessage";

export const addApplication =
  ({ data, history, companyId }) =>
  (dispatch) => {
    dispatch({
      type: "ADD_APPLICATION_LOADING",
      payload: true,
    });
    InsurecoreAPI.post(`/applications`, data)
      .then((response) => {
        sessionStorage.setItem("applicationId", response.data.data.id);
        dispatch({
          type: "ADD_UPDATE_APPLICATION",
          payload: response.data.data,
        });
        showSuccessMessage(successMessage(response));
        const userData = JSON.parse(sessionStorage.getItem("user"));
        const invoffuserid = userData?.invoffuserid;
        dispatch(getDashboardApi({ invoffuserid, setSessionStorage: false }));
        history.push(`/application/${response?.data?.data?.id}/overview`);
        dispatch({
          type: "ADD_APPLICATION_LOADING",
          payload: false,
        });
      })
      .catch(function (error) {
        showErrorMessage(errorMessage(error));
        dispatch({
          type: "ADD_APPLICATION_LOADING",
          payload: false,
        });
      });
  };

export const getAllApplication = (companyId) => (dispatch) => {
  dispatch({
    type: "GET_APPLICATION_REQ",
  });
  InsurecoreAPI.get(`/applications?company_id=${companyId}&is_pagination=false`)
    .then((response) => {
      dispatch({ type: "GET_APPLICATION", payload: response.data });
    })
    .catch(function (error) {
      showErrorMessage(errorMessage(error));
      dispatch({
        type: "GET_APPLICATION_FAIL",
      });
    });
};

export const getSubStages = () => (dispatch) => {
  InsurecoreAPI.get(`/reference-data/sub-stages?uw_stage=2`)
    .then((response) => {
      dispatch({ type: "GET_SUBSTAGES", payload: response.data });
    })
    .catch(function (error) {
      showErrorMessage(errorMessage(error));
    });
};

export const getSingleApplication =
  (id, relatedQuoteParticipants) => (dispatch) => {
    dispatch({
      type: "SINGLE_APP_LOADING",
      payload: true,
    });
    InsurecoreAPI.get(
      `/applications/${id}${
        relatedQuoteParticipants ? "?get_quote_participants=true" : ""
      }`
    )
      .then((response) => {
        dispatch({
          type: "GET_SINGLE_APPLICATION",
          payload: response.data.data,
        });
        dispatch({
          type: "SINGLE_APP_LOADING",
          payload: false,
        });
      })
      .catch((error) => {
        showErrorMessage(errorMessage(error));
        dispatch({
          type: "SINGLE_APP_LOADING",
          payload: false,
        });
      });
  };

export const delegateUser =
  ({ payload, applicationId, handleClose }) =>
  (dispatch) => {
    const setLoading = (payload) =>
      dispatch({ type: "DELEGATE_USER_LOADING", payload });
    setLoading(true);
    InsurecoreAPI.post(`/applications/${applicationId}/delegate`, payload)
      .then((response) => {
        showSuccessMessage(successMessage(response));
        dispatch(getSingleApplication(applicationId));
        setLoading(false);
        handleClose?.();
      })
      .catch((error) => {
        setLoading(false);
        showErrorMessage(errorMessage(error));
      });
  };

export const undelegateUser =
  ({ applicationId, payload, handleClose, setOpenAlert }) =>
  (dispatch) => {
    dispatch({
      type: "UNDELEGATE_LOADING",
      payload: true,
    });
    InsurecoreAPI.post(`applications/${applicationId}/un-delegate`, payload)
      .then((response) => {
        dispatch(getSingleApplication(applicationId));
        setOpenAlert(false);
        handleClose(false);
        showSuccessMessage(successMessage(response));
        dispatch({
          type: "UNDELEGATE_LOADING",
          payload: false,
        });
      })
      .catch((error) => {
        showErrorMessage(errorMessage(error));
        dispatch({
          type: "UNDELEGATE_LOADING",
          payload: false,
        });
      });
  };

export const addUser =
  ({
    param,
    applicationId,
    sectionId,
    subSectionId,
    invitationData,
    isNewUser,
    handleClose,
  }) =>
  (dispatch) => {
    const setAddLoading = (payload) =>
      dispatch({ type: "ADD_USER_LOADING", payload });
    const setInviteLoading = (payload) =>
      dispatch({ type: "INVITE_USER_LOADING", payload });
    setAddLoading(true);
    PortalCompanyAPI.post(`contacts?create_crm_contact=true`, param)
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          const contactResponseData = response?.data?.data;
          setAddLoading(false);
          setInviteLoading(true);
          PortalCompanyAPI.post(`/contacts/${contactResponseData.id}/invite`, {
            ...invitationData,
            created_by: contactResponseData?.created_by?.id,
          })
            .then((inviteResponse) => {
              const inviteResponseData = inviteResponse?.data?.data;
              setInviteLoading(false);
              const delegateData = [
                {
                  user_id: inviteResponseData?.user?.invoffuserid,
                  contact_id: contactResponseData?.id,
                  contact_role: inviteResponseData?.user?.role_permission_id,
                  section_id: sectionId,
                  subsection_id: subSectionId,
                  user_email: contactResponseData?.email,
                  first_name: contactResponseData?.first_name,
                  last_name: contactResponseData?.last_name,
                  company_name: contactResponseData?.company?.name,
                  delegate_new_user: isNewUser,
                },
              ];
              dispatch(
                delegateUser({
                  payload: delegateData,
                  applicationId,
                  handleClose,
                })
              );
            })
            .catch(() => {
              setInviteLoading(false);
            });
        }
      })
      .catch(function (error) {
        showErrorMessage(errorMessage(error));
        setAddLoading(false);
        setInviteLoading(false);
      });
  };

export const updateApplication =
  ({ payload, applicationId }) =>
  (dispatch) => {
    InsurecoreAPI.patch(`/applications/${applicationId}`, payload)
      .then((response) => {
        dispatch({
          type: "ADD_UPDATE_APPLICATION",
          payload: response.data.data,
        });
      })
      .catch(function (error) {
        showErrorMessage(errorMessage(error));
      });
  };

export const getSectionQuestion =
  ({ sectionId, applicationId }) =>
  (dispatch) => {
    dispatch({
      type: "SECTION_QUESTION_LOADING",
      payload: true,
    });
    InsurecoreAPI.get(
      `/questionnaire/filter?section_id=${sectionId}&application_id=${applicationId}&is_skipped=true&is_answered=true`
    )
      .then((response) => {
        dispatch({
          type: "GET_SECTION_QUESTION",
          payload: response.data.data,
        });
        dispatch({
          type: "SECTION_QUESTION_LOADING",
          payload: false,
        });
      })
      .catch(function (error) {
        showErrorMessage(errorMessage(error));
        dispatch({
          type: "SECTION_QUESTION_LOADING",
          payload: false,
        });
      });
  };

export const sumbitApplicaiton =
  ({
    applicationId,
    appPayload,
    isReview,
    setOpenModal,
    history,
    showLoader,
  }) =>
  (dispatch) => {
    showLoader &&
      dispatch({
        type: "SUBMIT_QUESTION_LOADING",
        payload: true,
      });
    InsurecoreAPI.post(`/applications/${applicationId}/submit`, appPayload)
      .then((response) => {
        dispatch({
          type: "SUBMIT_APPLICATION",
          payload: response.data.data,
        });
        showSuccessMessage(successMessage(response) ?? "sucess");
        dispatch({
          type: "GET_SINGLE_APPLICATION",
          payload: response.data.data,
        });
        !isReview &&
          history.push({
            pathname: `/application/${response.data.data.id}/submitted`,
          });
        isReview && setOpenModal(true);
        dispatch({
          type: "SUBMIT_QUESTION_LOADING",
          payload: false,
        });
      })
      .catch(function (error) {
        showErrorMessage(errorMessage(error));
        dispatch({
          type: "SUBMIT_QUESTION_LOADING",
          payload: false,
        });
      });
  };

export const getQuestionaries =
  ({
    sendQuestionId,
    prevNavigation,
    nextNavigation,
    applicationId,
    sub_section_id,
    hasDependency,
    fieldValue,
    currentNavigation,
    isSkipped,
    setAnswers,
    setRepeatIndex,
  }) =>
  (dispatch) => {
    dispatch({
      type: "SINGLE_LOADING",
      payload: true,
    });
    sessionStorage.removeItem("repeat_question");
    setAnswers?.([]);
    dispatch({
      type: "SHOW_SNACKBAR",
      payload: false,
    });
    const valueToSend = fieldValue || "";
    InsurecoreAPI.get(
      `/applications/${applicationId}/questionnaire?navigation=${
        nextNavigation || prevNavigation || currentNavigation
      }${
        sendQuestionId === 0
          ? ""
          : !sendQuestionId
          ? ""
          : "&question_id=" + sendQuestionId
      }&sub_section_id=${sub_section_id}${
        hasDependency && nextNavigation ? "&answer=" + valueToSend : ""
      }${isSkipped ? "&is_skipped=true" : ""}`
    )
      .then((response) => {
        dispatch({
          type: "GET_QUESTIONARIES",
          payload: response.data,
        });
        setRepeatIndex?.(0);
        dispatch({
          type: "GET_TIME",
          payload: moment().valueOf(),
        });
        dispatch({
          type: "SINGLE_LOADING",
          payload: false,
        });
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      })
      .catch((error) => {
        showErrorMessage(errorMessage(error));
        dispatch({
          type: "SINGLE_LOADING",
          payload: false,
        });
      });
  };

export const sendQuestionAnswers =
  ({
    applicationId,
    payload,
    sendPatch = true,
    sendQuestionId,
    nextNavigation,
    sub_section_id,
    hasDependency,
    fieldValue,
    setAnswers,
    getTime,
    isSkipped,
    setRepeatIndex,
  }) =>
  (dispatch) => {
    dispatch({
      type: "LOADING",
      payload: true,
    });
    const userData = JSON.parse(sessionStorage.getItem("user"));
    const user_id = userData?.invoffuserid;
    const lengthOfAnswers = payload?.question_answers.length;
    const payloadWithTime = (moment().valueOf() - getTime) / lengthOfAnswers;
    let newPayload = payload?.question_answers?.map((items) => {
      return {
        ...items,
        time_taken: payloadWithTime,
      };
    });
    newPayload = { question_answers: newPayload, user_id: user_id };
    if (sendPatch) {
      InsurecoreAPI.patch(`/applications/${applicationId}`, newPayload)
        .then((response) => {
          dispatch({
            type: "ADD_UPDATE_QUESTIONS",
            payload: response.data?.data,
          });
          setAnswers([]);
          dispatch(
            getQuestionaries({
              sendQuestionId,
              nextNavigation,
              applicationId,
              sub_section_id,
              hasDependency,
              fieldValue,
              isSkipped,
              setAnswers,
              setRepeatIndex,
            })
          );
          sessionStorage.removeItem("repeat_question");
          dispatch({
            type: "REPEAT_QUESTION",
            payload: {},
          });
          dispatch({
            type: "GET_SINGLE_APPLICATION",
            payload: response.data.data,
          });
          dispatch({
            type: "LOADING",
            payload: false,
          });
        })
        .catch((error) => {
          showErrorMessage(errorMessage(error));
          dispatch({
            type: "LOADING",
            payload: false,
          });
        });
    } else {
      setAnswers([]);
      dispatch(
        getQuestionaries({
          sendQuestionId,
          nextNavigation,
          applicationId,
          sub_section_id,
          hasDependency,
          fieldValue,
          isSkipped,
          setAnswers,
          setRepeatIndex,
        })
      );
      sessionStorage.removeItem("repeat_question");
      dispatch({
        type: "REPEAT_QUESTION",
        payload: {},
      });
      dispatch({
        type: "LOADING",
        payload: false,
      });
    }
  };

export const setIsSkip = (payload) => (dispatch) => {
  dispatch({
    type: "SET_IS_SKIP",
    payload,
  });
  sessionStorage.removeItem("repeat_question");
};

export const getConsentDocument = () => (dispatch) => {
  const loggedUserData = JSON.parse(sessionStorage.getItem("loggedUserData"));
  const party_business = loggedUserData?.user_data?.party_business?.id;
  storageApi
    .get(`static-docs?business_id=${party_business}&category=term_of_business`)
    .then((response) => {
      if (response.data) {
        dispatch({
          type: "GET_CONSENT_DOCUMENT",
          payload: response.data,
        });
      }
    })
    .catch((error) => {
      showErrorMessage(errorMessage(error));
    });
};

export const getFcaDetails =
  (applicationId, question_id, value) => (dispatch) => {
    dispatch({
      type: "FCA_LOADING_REQ",
    });

    const postData = {
      question_id: question_id,
      value: value,
    };
    InsurecoreAPI.post(`/applications/${applicationId}/finder-api`, postData)
      .then((response) => {
        if (response.data) {
          dispatch({
            type: "GET_FCA_DETAILS",
            payload: response.data,
          });
        }
        dispatch({
          type: "FCA_LOADING_SUC",
        });
      })
      .catch((error) => {
        dispatch({
          type: "FCA_LOADING_FAIL",
        });
        showErrorMessage(errorMessage(error));
      });
  };

export const updateRingProgressIndex = (index) => (dispatch) => {
  dispatch({
    type: "RING_PROGRESS_INDEX",
    payload: index,
  });
};

export const getApplicationResources = (productId) => (dispatch) => {
  dispatch({
    type: "RESOURCES_LOADING",
    payload: true,
  });
  InsurecoreAPI.get(`/applications/resources?product_id=${productId}`)
    .then((res) => {
      dispatch({
        type: "APPLICATION_RESOURCES",
        payload: res.data,
      });
      dispatch({
        type: "RESOURCES_LOADING",
        payload: false,
      });
    })
    .catch((error) => {
      showErrorMessage(errorMessage(error));
      dispatch({
        type: "RESOURCES_LOADING",
        payload: false,
      });
    });
};

export const getSingleQuote =
  ({ quoteId, hideLoading = false }) =>
  (dispatch) => {
    dispatch({
      type: "GET_SINGLE_QUOTE_LOADING",
      payload: hideLoading ? false : true,
    });
    InsurecoreAPI.get(`/customer/quotes/${quoteId}?details=full`)
      .then((res) => {
        dispatch({
          type: "GET_SINGLE_QUOTE",
          payload: res.data,
        });
        dispatch({
          type: "GET_SINGLE_QUOTE_LOADING",
          payload: false,
        });
      })
      .catch((error) => {
        showErrorMessage(errorMessage(error));
        dispatch({
          type: "GET_SINGLE_QUOTE_LOADING",
          payload: false,
        });
      });
  };

export const getInternalUser = (userId) => (dispatch) => {
  CoreAPI.get(`/core/user/basic-detail?invoffuserid=${userId}`)
    .then((res) => {
      dispatch({
        type: "GET_INTERNAL_USER",
        payload: res.data,
      });
    })
    .catch((error) => {
      showErrorMessage(errorMessage(error));
    });
};

export const getDocument =
  (id, directDownload = false) =>
  (dispatch) => {
    storageApi
      .get(`/get-doc/${id}`)
      .then((res) => {
        dispatch({
          type: "GET_QUOTE_DOCUMENT",
          payload: res.data,
        });
        if (directDownload) {
          fetch(res.data?.data?.document).then((response) => {
            response.blob().then((blob) => {
              const fileURL = window.URL.createObjectURL(blob);
              let alink = document.createElement("a");
              alink.href = fileURL;
              alink.download = res.data?.data?.name ?? "policy";
              alink.click();
            });
          });
        }
      })
      .catch((error) => {
        showErrorMessage(errorMessage(error));
      });
  };

export const acceptQuote =
  ({ quoteId, setOpenDialog, applicationId }) =>
  (dispatch) => {
    dispatch({
      type: "ACCEPT_QUOTE_LOADING",
      payload: true,
    });
    InsurecoreAPI.post(`/quotes/${quoteId}/accept`)
      .then((response) => {
        dispatch({
          type: "ACCEPT_QUOTE_LOADING",
          payload: false,
        });
        dispatch(getSingleQuote({ quoteId }));
        setOpenDialog(false);
        dispatch(getSingleApplication(applicationId));
        showSuccessMessage(successMessage(response));
      })
      .catch((error) => {
        dispatch({
          type: "ACCEPT_QUOTE_LOADING",
          payload: false,
        });
        showErrorMessage(errorMessage(error));
      });
  };

export const sendAcceptSignature =
  ({ quoteId, payload }) =>
  (dispatch) => {
    InsurecoreAPI.post(`/quotes/${quoteId}/documents`, payload)
      .then(() => {
        dispatch({
          type: "ACCEPT_SIGNATURE",
          payload: true,
        });
      })
      .catch((error) => {
        showErrorMessage(errorMessage(error));
      });
  };

export const viewDocument =
  ({ quoteId, documentId, payload }) =>
  (dispatch) => {
    InsurecoreAPI.patch(`/quotes/${quoteId}/documents/${documentId}`, payload)
      .then(() => {
        dispatch({
          type: "VIEW_DOCUMENT_REQ",
          payload: true,
        });
      })
      .catch((error) => {
        showErrorMessage(errorMessage(error));
      });
  };

export const paymentScheme = (policyId) => (dispatch) => {
  dispatch({
    type: "GET_PAYMENT_LOADING",
    payload: true,
  });
  InsurecoreAPI.post(`/policies/${policyId}/payment-schemes`)
    .then((res) => {
      dispatch({
        type: "GET_PAYMENT_DETAILS",
        payload: res.data,
      });
      dispatch({
        type: "GET_PAYMENT_LOADING",
        payload: false,
      });
    })
    .catch((error) => {
      showErrorMessage(errorMessage(error));
      dispatch({
        type: "GET_PAYMENT_LOADING",
        payload: false,
      });
    });
};

export const getOtherGuideResources = () => (dispatch) => {
  const loggedUserData = JSON.parse(sessionStorage.getItem("loggedUserData"));
  const party_business = loggedUserData?.user_data?.party_business?.id;
  storageApi
    .get(
      `static-docs?business_id=${party_business}&category=user_guides&is_deleted=false`
    )
    .then((response) => {
      if (response.data) {
        dispatch({
          type: "GET_SYSTEM_GUIDES",
          payload: response.data,
        });
      }
    })
    .catch((error) => {
      showErrorMessage(errorMessage(error));
    });
};

export const choosePaymentScheme =
  ({ policyId, quoteId, payload, setPaymentDialogOpen, applicationId }) =>
  (dispatch) => {
    dispatch({
      type: "CHOOSE_PAYMENT_LOADING",
      payload: true,
    });
    InsurecoreAPI.post(
      `/policies/${policyId}/payment-schemes/chosen-scheme`,
      payload
    )
      .then((response) => {
        dispatch(getSingleApplication(applicationId));
        dispatch(getSingleQuote({ quoteId }));
        setPaymentDialogOpen(false);
        showSuccessMessage(successMessage(response));
        dispatch({
          type: "CHOOSE_PAYMENT_LOADING",
          payload: false,
        });
      })
      .catch((error) => {
        const response = error?.response?.data;
        const message = response?.message;
        const validationMessage = response?.errors?.[0]?.Message;
        const combineErrorMessage = `${message ?? ""} ${
          validationMessage ?? ""
        }`;
        showErrorMessage(combineErrorMessage);
        dispatch({
          type: "CHOOSE_PAYMENT_LOADING",
          payload: false,
        });
        setPaymentDialogOpen(false);
        dispatch({
          type: "PAYMENT_FAILED",
          payload: true,
        });
      });
  };

export const updateSubjectivity =
  ({ quoteId, subId, payload, setButtonIndex, setSelectedFile }) =>
  (dispatch) => {
    dispatch({
      type: "UPDATE_SUBJ_LOADING",
      payload: true,
    });
    InsurecoreAPI.patch(`/quotes/${quoteId}/subjectivities/${subId}`, payload)
      .then((response) => {
        if (response?.data?.status) {
          showSuccessMessage(successMessage(response));
          dispatch(getSingleQuote({ quoteId, hideLoading: true }));
          setSelectedFile(null);
        } else {
          showErrorMessage(errorMessage(response));
        }
        dispatch({
          type: "UPDATE_SUBJ_LOADING",
          payload: false,
        });
        setButtonIndex(null);
      })
      .catch((error) => {
        showErrorMessage(errorMessage(error));
        setButtonIndex(null);
        dispatch({
          type: "UPDATE_SUBJ_LOADING",
          payload: false,
        });
      });
  };

export const getDashboardApi =
  ({ invoffuserid, setSessionStorage }) =>
  (dispatch) => {
    dispatch({
      type: "GET_DASHBOARD_LOADING",
      payload: true,
    });
    CoreAPI.get(`customer-portal/dashboard?current_user=${invoffuserid}`)
      .then((res) => {
        dispatch({
          type: "GET_DASHBOARD_DETAILS",
          payload: res.data,
        });
        const applicationCount = res?.data?.data?.total_count?.applications;
        setSessionStorage &&
          sessionStorage.setItem("application_count", applicationCount);
        dispatch({
          type: "GET_DASHBOARD_LOADING",
          payload: false,
        });
      })
      .catch((error) => {
        showErrorMessage(errorMessage(error));
        dispatch({
          type: "GET_DASHBOARD_LOADING",
          payload: false,
        });
      });
  };

export const getOtherVideoResources = () => (dispatch) => {
  const loggedUserData = JSON.parse(sessionStorage.getItem("loggedUserData"));
  const party_business = loggedUserData?.user_data?.party_business?.id;
  storageApi
    .get(
      `static-docs?business_id=${party_business}&category=system_videos&is_deleted=false`
    )
    .then((response) => {
      if (response.data) {
        dispatch({
          type: "GET_SYSTEM_VIDEOS",
          payload: response.data,
        });
      }
    })
    .catch((error) => {
      showErrorMessage(errorMessage(error));
    });
};

export const showResources = (payload) => (dispatch) => {
  dispatch({
    type: "SHOW_RESOURCES_DRAWER",
    payload: payload,
  });
};
