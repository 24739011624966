import * as React from "react";
import { styled } from "@mui/material/styles";
import { Button, CircularProgress, Stack, Typography } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import ChevronCircleRight from "../components/presentation/icons/ChevronCircleRight";
import FileDownload from "../components/presentation/icons/FileDownload";
import Renewal from "../components/presentation/icons/Renewal";
import Pencil from "../components/presentation/icons/Pencil";
import PolicyNotifications from "../components/presentation/icons/PolicyNotifications";
import Print from "../components/presentation/icons/Print";
import Envelope from "../components/presentation/icons/Envelope";
import User from "../components/presentation/icons/User";
import Calendar from "../components/presentation/icons/Calendar";
import { Link } from "react-router-dom";

export default function IconLabelButton(props) {
  let buttonBackgroundColor,
    buttonBackgroundImage,
    buttonBackgroundPosition,
    buttonBackgroundColorHover,
    buttonColor,
    buttonColorHover;

  if (props.buttonColor === "gradient") {
    buttonBackgroundImage =
      "linear-gradient(to right, #12d5ff 0%, #546bff 51%, #920afe 100%)";
    buttonBackgroundPosition = " background-position: right center";
    buttonBackgroundColor = "primary.main";
    buttonBackgroundColorHover = "primary.hover";
    buttonColorHover = props.buttonColorHover ?? props.textColor;
  } else if (props.buttonColor === "solid") {
    buttonColor = "#fff";
    buttonColorHover = "#fff";
    buttonBackgroundColor = "#920afe";
    buttonBackgroundColorHover = "#546bff";
  } else if (props.variant === "text") {
    buttonColor = props.buttonColor;
    buttonColorHover = props.buttonColor;
    buttonBackgroundColor = "transparent";
    buttonBackgroundColorHover = "transparent";
  } else {
    buttonBackgroundColor = props.buttonColor;
    buttonBackgroundColorHover =
      props.buttonBackgroundColorHover || props.buttonColor;
    buttonColorHover = props.buttonColorHover ?? props.textColor;
  }
  const ColorButton = styled(Button)(({ theme }) => ({
    color: buttonColor || "primary.main",
    backgroundColor: buttonBackgroundColor,
    backgroundPosition: buttonBackgroundPosition,
    backgroundImage: buttonBackgroundImage,
    opacity: 1,
    "&:hover": {
      color: buttonColorHover || "primary.main",
      backgroundColor: buttonBackgroundColorHover,
      backgroundPosition: buttonBackgroundPosition,
      opacity: 0.6,
    },
    borderRadius: 30,
    padding: "10px 20px",
    fontWeight: "bold",
  }));

  let buttonIcon;
  let buttonElement;
  let buttonAlign;
  if (props.iconType === "check") {
    buttonIcon = <CheckIcon />;
  } else if (props.iconType === "edit") {
    buttonIcon = <Pencil />;
  } else if (props.iconType === "loading") {
    buttonIcon = (
      <CircularProgress
        sx={
          props?.loadingColor
            ? { color: props?.loadingColor }
            : { color: "#FFF" }
        }
        size="20px"
      />
    );
  } else if (props.iconType === "calendar") {
    buttonIcon = <Calendar />;
  } else if (props.iconType === "flag") {
    buttonIcon = <PolicyNotifications />;
  } else if (props.iconType === "back") {
    buttonIcon = <ChevronLeftIcon />;
  } else if (props.iconType === "close") {
    buttonIcon = <CloseIcon />;
  } else if (props.iconType === "ChevronCircleRight") {
    buttonIcon = <ChevronCircleRight />;
  } else if (props.iconType === "download") {
    buttonIcon = <FileDownload />;
  } else if (props.iconType === "renewal") {
    buttonIcon = <Renewal />;
  } else if (props.iconType === "print") {
    buttonIcon = <Print />;
  } else if (props.iconType === "email") {
    buttonIcon = <Envelope />;
  } else if (props.iconType === "user") {
    buttonIcon = <User />;
  } else {
    buttonIcon = <ChevronRightIcon />;
  }
  if (props.buttonAlign === "left") {
    buttonAlign = "left";
  } else if (props.buttonAlign === "right") {
    buttonAlign = "right";
  } else {
    buttonAlign = "center";
  }

  if (props.iconPosition === "start") {
    buttonElement = (
      <ColorButton
        onClick={props.onClick}
        disableRipple={props.disableRipple}
        variant={props.variant}
        startIcon={buttonIcon}
        sx={props.sxStyle}
        style={{ textAlign: buttonAlign, display: "flex" }}
      >
        {props.mylabel}
      </ColorButton>
    );
  } else {
    buttonElement = (
      <ColorButton
        onClick={props.onClick}
        disableRipple={props.disableRipple}
        variant={props.variant}
        endIcon={buttonIcon}
        sx={props.sxStyle}
        style={{ textAlign: buttonAlign }}
      >
        {props.mylabel}
      </ColorButton>
    );
  }
  return (
    <Stack
      mt={2}
      mb={2}
      direction="row"
      spacing={4}
      justifyContent={buttonAlign}
    >
      {props.isDisable ? (
        <Typography
          variant="button"
          style={{ textDecoration: "none" }}
          // sx={props.sxStyle}
        >
          {buttonElement}
        </Typography>
      ) : props?.dataRef ? (
        <Link
          to={props?.dataRef}
          variant="button"
          style={{
            textDecoration: "none",
          }}
          sx={props.sxStyle}
        >
          {buttonElement}
        </Link>
      ) : (
        <Typography
          variant="button"
          style={{
            textDecoration: "none",
          }}
          sx={props.sxStyle}
        >
          {buttonElement}
        </Typography>
      )}
    </Stack>
  );
}
