const initialState = {
  company_detail: [],
  countries: [],
  states: [],
  cities: [],
  regions: [],
  userProfileData: [],
  businessExpertise: [],
  userExpertise: [],
  businessServices: [],
  userServices: [],
  businessProducts: [],
  userProducts: [],
  businessAreaServed: [],
  userAreaServed: [],
  businessDetail: {},
  userLocation: [],
  refBusinessType: [],
  refBusinessIndustry: [],
  refBusinessProducts: [],
  refBusinessServices: [],
  businessTeamData: [],
  user_available_slots: [],
  timezones: [],
  appointementRes: {},
  hierarchicalBusinessAreaServed: [],
  deleteAreaServed: {},
  deleteExpertise: {},
  deleteService: {},
  deleteProduct: {},
  chatRequestResponse: {},
  companyLoading: false,
  companyFca: [],
};

const companyReducer = (state = initialState, action) => {
  const { payload, type } = action;

  switch (type) {
    case "GET_COMPANY_DETAIL":
      return {
        ...state,
        company_detail: payload,
      };
    case "COMPANY_LOADING":
      return {
        ...state,
        companyLoading: payload,
      };
    case "GET_REGIONS":
      return {
        ...state,
        regions: action.payload,
      };

    case "GET_COUNTRIES":
      return {
        ...state,
        countries: action.payload,
      };
    case "GET_STATES":
      return {
        ...state,
        states: action.payload,
      };

    case "GET_CITIES":
      return {
        ...state,
        cities: action.payload,
      };

    case "GET_USER_PROFILE_DATA":
      return {
        ...state,
        userProfileData: action.payload,
      };

    case "GET_BUSINESS_EXPERTISE":
      return {
        ...state,
        businessExpertise: action.payload,
      };

    case "GET_USER_EXPERTISE":
      return {
        ...state,
        userExpertise: action.payload,
      };

    case "GET_BUSINESS_SERVICES":
      return {
        ...state,
        businessServices: action.payload,
      };
    case "GET_USER_SERVICES":
      return {
        ...state,
        userServices: action.payload,
      };

    case "GET_BUSINESS_PRODUCTS":
      return {
        ...state,
        businessProducts: action.payload,
      };

    case "GET_USER_PRODUCTS":
      return {
        ...state,
        userProducts: action.payload,
      };

    case "GET_BUSINESS_AREASERVED":
      return {
        ...state,
        businessAreaServed: action.payload,
      };
    case "DELETE_AREASERVED":
      return {
        ...state,
        deleteAreaServed: action.payload,
      };
    case "DELETE_EXPERTISE":
      return {
        ...state,
        deleteExpertise: action.payload,
      };
    case "DELETE_SERVICE":
      return {
        ...state,
        deleteService: action.payload,
      };
    case "DELETE_PRODUCT":
      return {
        ...state,
        deleteProduct: action.payload,
      };
    case "GET_USER_AREASERVED":
      return {
        ...state,
        userAreaServed: action.payload,
      };
    case "GET_BUSINESS_DETAIL":
      return {
        ...state,
        businessDetail: payload,
      };

    case "GET_USER_LOCATION":
      return {
        ...state,
        userLocation: payload,
      };

    case "GET_REF_BUSINESS_TYPE":
      return {
        ...state,
        refBusinessType: payload,
      };

    case "GET_REF_BUSINESS_INDUSTRY":
      return {
        ...state,
        refBusinessIndustry: payload,
      };

    case "GET_REF_BUSINESS_PRODUCTS":
      return {
        ...state,
        refBusinessProducts: payload,
      };

    case "GET_REF_BUSINESS_SERVICES":
      return {
        ...state,
        refBusinessServices: payload,
      };

    case "GET_BUSINESS_TEAM_DATA":
      return {
        ...state,
        businessTeamData: payload,
      };

    case "GET_AVAILABLE_SLOTS":
      return {
        ...state,
        user_available_slots: payload,
      };

    case "GET_TIME_ZONES":
      return {
        ...state,
        timezones: payload,
      };

    case "APPOINTMENT_RESPONSE":
      return {
        ...state,
        appointementRes: payload,
      };

    case "HIERARCHICAL_BUSINESS_AREASERVED":
      return {
        ...state,
        hierarchicalBusinessAreaServed: payload,
      };
    case "SEND_CHAT_REQUEST":
      return {
        ...state,
        chatRequestResponse: action.payload,
      };

    case "GET_COMPANY_FCA":
      return {
        ...state,
        companyFca: action.payload,
      };
    default:
      return state;
  }
};

export default companyReducer;
