const initialState = {
  allNews: {},
  allNewsLoading: false,
  singleNews: {},
  singleNewsLoading: false,
};

const newsReducer = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case "GET_ALL_NEWS":
      return {
        ...state,
        allNews: payload,
        allNewsLoading: false,
      };
    case "GET_ALL_NEWS_REQ":
      return {
        ...state,
        allNewsLoading: true,
      };
    case "GET_ALL_NEWS_FAIL":
      return {
        ...state,
        allNewsLoading: false,
      };
    case "GET_SINGLE_NEWS":
      return {
        ...state,
        singleNews: payload,
        singleNewsLoading: false,
      };
    case "GET_SINGLE_NEWS_REQ":
      return {
        ...state,
        singleNewsLoading: true,
      };
    case "GET_SINGLE_NEWS_FAIL":
      return {
        ...state,
        singleNewsLoading: false,
      };
    default: {
      return {
        ...state,
      };
    }
  }
};

export default newsReducer;
