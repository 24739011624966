import Paper from "@mui/material/Paper";
import React, { useEffect, useState } from "react";
import Modal from "../../../utils/Modal";
import Chat from "../icons/Chat";
import {
  Box,
  Button,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextareaAutosize,
  Typography,
  useTheme,
} from "@mui/material";
import Calendar from "../../../utils/Calendar";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  getAvailableSlot,
  getQueryType,
  requestSlot,
} from "../../../actions/availability";
import { BR_EMAIL } from "../../../utils/constants";

const ContactBox = ({ fromApplication }) => {
  const [openEmail, setOpenEmail] = useState(false);
  const [openPhone, setOpenPhone] = useState(false);
  const handleClickEmailOpen = () => {
    setOpenEmail(true);
  };

  const handleClickPhoneOpen = () => {
    setOpenPhone(true);
  };

  const mailDialogTitle = "You have requested a call";

  const [queryCode, setQueryCode] = useState("");
  const { requestTimeSlotLoading } = useSelector((state) => state.availability);

  const contactDialogTitle = "Contact Us";
  const authUserData = JSON.parse(sessionStorage.getItem("loggedUserData"));
  const current_user = authUserData.user_data?.invoffuserid;
  const fullName = `${authUserData?.user_data?.first_name ?? ""} ${
    authUserData?.user_data?.last_name ?? ""
  }`;
  const companyName = `(${authUserData?.user_data?.company?.name})`;
  const user_email = authUserData?.user_data?.username;
  const [userValue, setUserValue] = useState();
  const [description, setDescription] = useState("");
  const dispatch = useDispatch();
  const [times, setTimes] = useState("");
  const handlePhoneConfirm = () => {
    const code = queryCode;
    const payload = {
      request_by_id: current_user,
      request_by_email: user_email,
      subject: `${fullName}${companyName}`,
      description: description,
      start_date_time: times?.start_datetime,
      end_date_time: times?.end_datetime,
      user_id: userValue,
      // duration:duration
    };
    dispatch(requestSlot({ code, current_user, payload, setOpenPhone }));
  };

  const handleEmailConfirm = () => {
    window.open(`mailto:${BR_EMAIL}`, "_blank");
  };
  useEffect(() => {
    if (!openPhone) {
      setUserValue("");
    }
  }, [openPhone]);

  return (
    <Paper
      elevation={2}
      sx={{
        position: fromApplication ? "" : "relative",
        zIndex: 50,
        top: fromApplication && 450,
        left: fromApplication && 20,
        textAlign: "center",
        borderRadius: 2,
        width: "fit-content",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Button
          variant="outlined"
          onClick={handleClickEmailOpen}
          sx={{
            border: 0,
            p: 0,
            width: "40px",
            height: "40px",
          }}
        >
          <EmailOutlinedIcon />
        </Button>
        <Button
          variant="outlined"
          onClick={handleClickPhoneOpen}
          sx={{
            border: 0,
            p: 0,
            width: "40px",
            height: "40px",
          }}
        >
          <PhoneOutlinedIcon />
        </Button>
        {/* <Button
          variant="outlined"
          sx={{
            border: 0,
            p: 0,
            width: "40px",
            height: "40px",
          }}
        >
          <Chat color="primary" sx={{ margin: "10px auto" }} />
        </Button> */}
      </Box>
      {/* email modal */}
      <Modal
        open={openEmail}
        maxWidth={"610px"}
        setOpen={setOpenEmail}
        handleClickOpen={handleClickEmailOpen}
        modalType="schedule"
        dialogContent={<EmailContent />}
        dialogTitle={mailDialogTitle}
        handleConfirm={handleEmailConfirm}
      />
      {/* phone modal */}
      <Modal
        maxWidth={"600px"}
        isLoading={requestTimeSlotLoading}
        fromContactBox={true}
        times={times}
        open={openPhone}
        setOpen={setOpenPhone}
        handleClickOpen={handleClickPhoneOpen}
        modalType="schedule"
        dialogContent={
          <PhoneContent
            userValue={userValue}
            setUserValue={setUserValue}
            times={times}
            setTimes={setTimes}
            setDescription={setDescription}
            description={description}
            setQueryCode={setQueryCode}
          />
        }
        dialogTitle={contactDialogTitle}
        handleConfirm={handlePhoneConfirm}
      />
    </Paper>
  );
};

export const PhoneContent = ({
  times,
  setTimes,
  setDescription,
  description,
  setQueryCode,
  userValue,
  setUserValue,
}) => {
  const handleTimePick = (event) => {
    // const element=event.target;
    // element.setAttribute('color', 'error');
    setTimes(event);
  };
  const theme = useTheme();

  useEffect(() => {
    return () => {
      resetTime();
      setDescription("");
    };
  }, []);

  const resetTime = () => {
    setTimes("");
    //setDate('')
  };

  const [calendarValue, setCalendarValue] = useState(new Date());

  const [selectQuery, setSelectQuery] = useState(true);
  const [queryValue, setQueryValue] = useState("");
  const authUserData = JSON.parse(sessionStorage.getItem("loggedUserData"));
  const current_user = authUserData.user_data?.invoffuserid;
  const [durationValue, setDurationValue] = useState("");

  const onCalendarChange = (value) => {
    const code = queryValue?.code;
    const date = moment(value).format("YYYY-MM-DD");
    const user_id = userValue;
    dispatch(
      getAvailableSlot({
        current_user,
        code,
        date,
        user_id,
        duration: durationValue,
      })
    );
  };

  const durationList = [
    {
      value: "15",
      label: "15 Minutes",
    },
    {
      value: "30",
      label: "30 Minutes",
    },
    {
      value: "60",
      label: "60 Minutes",
    },
  ];

  const [selectQueryType, setSelectQueryType] = useState("");
  const [userList, setuserList] = useState();
  const handleDuration = (e) => {
    const value = e.target.value;
    setDurationValue(value);
    const date = moment().format("YYYY-MM-DD");
    const code = queryValue?.code;
    const user_id = userValue;
    dispatch(
      getAvailableSlot({
        current_user,
        code,
        date,
        user_id,
        duration: value,
      })
    );
    setSelectQuery(false);
  };

  const handleUserSelect = (e) => {
    const userId = e.target.value;
    setUserValue(userId);
  };

  const handleQuerySelect = (e) => {
    const { code, customer_support, type } = e.target.value;
    setuserList(customer_support);
    setSelectQueryType(type);
    setQueryValue(e.target.value);
    setQueryCode(code);
    // if (customer_support?.length > 1) {
    //   //
    // } else {
    //   const user_id = customer_support?.[0]?.user_id;
    //   setSelectQuery(false);
    //   setUserValue(user_id);
    //   const date = moment().format("YYYY-MM-DD");
    //   dispatch(getAvailableSlot({ current_user, code, date, user_id }));
    // }
  };

  const { queryType, availableSlot, requestTimeSlotLoading } = useSelector(
    (state) => state.availability
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getQueryType());
  }, [dispatch]);

  const handleBack = () => {
    setDurationValue("");
    setCalendarValue(new Date());
    setSelectQuery(true);
  };

  return (
    <Box>
      {selectQuery ? (
        <>
          <Typography variant="body1" sx={{ mb: 5 }}>
            Please Choose Query Type from an available options below
          </Typography>
          <Box width="50%">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Select Query Type
              </InputLabel>
              <Select
                onChange={handleQuerySelect}
                name="query"
                value={queryValue}
                margin="normal"
                required
                autoComplete="confirm-password"
              >
                {queryType?.data?.map((items, index) => {
                  return (
                    <MenuItem key={index} value={items}>
                      {items.type}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            {userList && userList?.length > 0 && (
              <>
                <FormControl sx={{ mt: 3 }} fullWidth>
                  <InputLabel id="select-user">Select User</InputLabel>
                  <Select
                    onChange={handleUserSelect}
                    name="select"
                    value={userValue}
                    margin="normal"
                    required
                  >
                    {userList?.map((items, index) => {
                      return (
                        <MenuItem key={index} value={items?.user_id}>
                          {items?.user_full_name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <FormControl sx={{ mt: 3 }} fullWidth>
                  {/* duration */}
                  <InputLabel id="select-user">Select Duration</InputLabel>
                  <Select
                    onChange={handleDuration}
                    name="select"
                    value={durationValue}
                    margin="normal"
                    required
                  >
                    {durationList?.map((items, index) => {
                      return (
                        <MenuItem key={index} value={items?.value}>
                          {items?.label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </>
            )}
          </Box>
        </>
      ) : (
        <>
          {times === "" ? (
            <>
              <Typography variant="body1" sx={{ mb: 2 }}>
                Please Choose from an available time slot below
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Button
                  sx={{
                    display: "flex",
                    justifyContent: "start",
                    width: "fit-content",
                  }}
                  onClick={handleBack}
                  variant="text"
                >
                  Change Query Type <span>({selectQueryType ?? ""})</span>
                </Button>
                <Button
                  sx={{
                    display: "flex",
                    justifyContent: "start",
                    width: "fit-content",
                  }}
                  onClick={handleBack}
                  variant="text"
                >
                  Change User
                  <span style={{ marginLeft: "5px" }}>
                    (
                    {userList?.find((item) => item.user_id == userValue)
                      ?.user_full_name ?? ""}
                    )
                  </span>
                </Button>
                <Button
                  sx={{
                    display: "flex",
                    justifyContent: "start",
                    width: "fit-content",
                  }}
                  onClick={handleBack}
                  variant="text"
                >
                  Change Duration
                  <span style={{ marginLeft: "5px" }}>
                    (
                    {durationList?.find((item) => item.value === durationValue)
                      ?.label ?? ""}
                    )
                  </span>
                </Button>
              </Box>
              <Box sx={{ display: "flex" }}>
                <Calendar
                  onChange={onCalendarChange}
                  calendarValue={calendarValue}
                  setCalendarValue={setCalendarValue}
                />
                <Box
                  height={400}
                  width={300}
                  sx={{
                    overflowY: "auto",
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "center",
                  }}
                >
                  <Typography
                    component="div"
                    variant="body1"
                    sx={{ mt: 2, mb: 2 }}
                  >
                    Choose time
                  </Typography>
                  {availableSlot?.data?.map((items, index) => {
                    return (
                      <Box key={index}>
                        <Chip
                          label={`${moment(items?.start_datetime).format(
                            "HH:mm"
                          )} - ${moment(items?.end_datetime).format("HH:mm")}`}
                          id={index}
                          variant="outlined"
                          color="primary"
                          data-value="0900"
                          sx={{ mb: 2 }}
                          onClick={() => handleTimePick(items)}
                        />
                      </Box>
                    );
                  })}
                  {!availableSlot?.data?.length > 0 && (
                    <Typography
                      component="div"
                      variant="body1"
                      sx={{ mt: 2, mb: 2, fontWeight: "300" }}
                    >
                      Slot not available
                    </Typography>
                  )}
                </Box>
              </Box>
            </>
          ) : (
            <>
              <TableContainer
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Table sx={{ width: 250 }} aria-label="simple table">
                  <TableBody>
                    <TableRow>
                      <TableCell
                        sx={{ padding: 1, border: 0 }}
                        component="th"
                        scope="row"
                      >
                        <Typography
                          sx={{ fontWeight: "bold", textAlign: "right" }}
                          component="p"
                          variant="body"
                        >
                          {" "}
                          Date:
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ padding: 1, border: 0 }}>
                        {moment(calendarValue).format("DD-MM-YYYY")}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        sx={{ padding: 1, border: 0 }}
                        component="th"
                        scope="row"
                      >
                        <Typography
                          sx={{ fontWeight: "bold", textAlign: "right" }}
                          component="p"
                          variant="body"
                        >
                          {" "}
                          Time:
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ padding: 1, border: 0 }}>
                        {moment(times?.start_datetime).format("HH:mm")} -{" "}
                        {moment(times?.end_datetime).format("HH:mm")}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <Box sx={{ textAlign: "center" }}>
                <Button onClick={resetTime} variant="text">
                  Change Date & Time
                </Button>
              </Box>
              <Typography component="p" variant="body" gutterBottom>
                To help us be best prepared, please briefly tell us what you’d
                like to discuss:
              </Typography>
              <TextareaAutosize
                aria-label="minimum height"
                minRows={3}
                onChange={(e) => setDescription(e.target.value)}
                value={description}
                placeholder=""
                style={{
                  fontFamily: "stolzl",
                  width: "100%",
                  borderRadius: "20px",
                  minHeight: "50px",
                  padding: "20px",
                  borderColor: theme.palette.primary.main,
                }}
              />
            </>
          )}
        </>
      )}
    </Box>
  );
};

export const EmailContent = () => {
  return (
    <Typography component="p" variant="body" sx={{ textAlign: "center" }}>
      If you would like to email, please contact your dedicated expert James at{" "}
      <a
        style={{ marginLeft: "4px" }}
        target="_blank"
        href={`mailto:${BR_EMAIL}`}
        rel="noreferrer"
      >
        {BR_EMAIL}
      </a>
    </Typography>
  );
};

export default ContactBox;
