export function showPageLoader() {
  return {
    type: "SHOW_PAGE_LOADER",
  };
}

export function hidePageLoader() {
  return {
    type: "HIDE_PAGE_LOADER",
  };
}

export function showSuccessError(response = null) {
  return {
    type: "SHOW_ALERT",
    payload: response,
  };
}

export function hideSuccessError(response = null) {
  return {
    type: "HIDE_ALERT",
    payload: response,
  };
}
