import { useTheme } from "@mui/material/styles";
import {
  Box,
  FormControl,
  FormLabel,
  FormControlLabel,
  Radio,
  RadioGroup,
  MenuItem,
  Tooltip,
  Select,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  TextField,
  Typography,
  InputLabel,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import IconLabelButton from "./IconLabelButton";
import Send from "../components/presentation/icons/Send";

import enGB from "date-fns/locale/en-GB";
import { useDispatch, useSelector } from "react-redux";

import { forwardRef, useEffect, useState } from "react";
import { delegateUser, addUser } from "../actions/insurance";
import moment from "moment";
import { getAllUsers, getRoles } from "../actions/user";
import { useInvoffUserid } from "../hooks/useLocalStorage";
import DescriptionTooltip from "../components/presentation/common/descriptionTooltip";
import useHasPermission from "../hooks/useHasPermission";
import { allPermissions } from "./constants";

const Transition = forwardRef(function Transition(props, ref) {
  const themed = useTheme();
  return (
    <Slide
      timeout={{ enter: 2000, exit: themed.transitions.duration.leavingScreen }}
      easing={{
        enter: themed.transitions.easing.easeInOut,
        exit: themed.transitions.easing.easeInOut,
      }}
      in
      direction="up"
      ref={ref}
      {...props}
    />
  );
});

export default function AlertDialogSlide(props) {
  const dispatch = useDispatch();
  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm();

  const contributors = props?.contributors;

  const canDelegateApplication = useHasPermission({
    permissionName: allPermissions.CAN_DELEGATE_APPLICATION,
  });
  const companyId = useSelector(
    (state) =>
      state.auth?.user?.contact?.company?.id || state.auth?.user?.company?.id
  );

  const { allUserList, roleData } = useSelector((store) => store.user);

  const [open, setOpen] = useState(false);
  const { delegateUserLoading, inviteUserLoading, addUserLoading } =
    useSelector((state) => state.insurance);

  const loading = delegateUserLoading || inviteUserLoading || addUserLoading;

  const invoffUserId = useInvoffUserid();

  const [filterUserList, setAllUser] = useState([]);

  useEffect(() => {
    setAllUser(
      allUserList?.data?.filter(
        (item) =>
          !contributors
            ?.map((contributor) => contributor.invoffuserid)
            ?.includes(item?.invoffuserid)
      )
    );
  }, [allUserList?.data, invoffUserId]);

  useEffect(() => {
    open && roleData?.length === 0 && dispatch(getRoles(party_id));
  }, [dispatch, open, roleData?.length]);

  useEffect(() => {
    open && dispatch(getAllUsers(companyId));
  }, [allUserList?.status, companyId, dispatch, open]);

  const colorIcon = props.iconcolor;
  const otherUsersExist = "yes";
  const availableUsers = 1;
  const MyComponent = forwardRef(function MyComponent(props, ref) {
    return (
      <div {...props} ref={ref} style={{ textAlign: "center" }}>
        {canDelegateApplication ? (
          <Send
            sx={{
              color: colorIcon,
              cursor: "pointer",
              "&:hover": { color: props.iconColorHover },
            }}
            onClick={(e) =>
              handleClickOpen(props.sectionId, props.subSectionId)
            }
          />
        ) : (
          <Send
            sx={{
              color: "grey",
            }}
          />
        )}
      </div>
    );
  });

  const [toolTipOpen, setToolTipOpen] = useState(false);

  const tooltipClose = () => {
    setToolTipOpen(false);
  };

  const handleOpen = () => {
    setToolTipOpen(true);
  };

  const handleClickOpen = () => {
    setOpen(true);
    tooltipClose();
  };

  const handleClose = () => {
    setOpen(false);
    reset();
    setAllUser([]);
    setValue("userId", null);
    setAddExp("no");
    setExpiryDate(null);
    setUserType("existing");
  };

  const [expiryDate, setExpiryDate] = useState(null);
  const [userType, setUserType] = useState("existing");
  const [addExp, setAddExp] = useState("no");

  const handleExpRadio = (e) => {
    setAddExp(e.target.value);
  };

  const handleRadioChange = (e) => {
    setUserType(e.target.value);
    reset();
    setAddExp("no");
    setExpiryDate(null);
  };

  const applicationId = sessionStorage.getItem("applicationId");
  const party_id = useSelector((state) => state.auth.user.party_business?.id);
  const company_id = useSelector(
    (state) =>
      state.auth?.user?.contact?.company?.id || state.auth?.user?.company?.id
  );

  const onSubmit = (values) => {
    const { firstName, lastName, email, jobTitle, userId, userLevel } = values;
    if (!loading) {
      if (!userId?.invoffuserid) {
        const sectionId = props.sectionId;
        const subSectionId = props.subSectionId;
        const isNewUser = true;
        const invitationData = {
          force_create: true,
          // created_by: loggedUserData?.user_data?.id,
          action_type: "send",
          // transaction_code: "delegate",
          role_permission_id: userLevel || undefined,
          delegate_new_user: true,
          user_expiry_date: expiryDate
            ? moment(expiryDate).format("YYYY-MM-DD")
            : null,
        };
        const param = {
          type: "Secondary",
          company: company_id,
          party_business: party_id,
          email: email,
          first_name: firstName,
          is_active: true,
          job_title: jobTitle,
          last_name: lastName,
        };
        dispatch(
          addUser({
            param,
            applicationId,
            sectionId,
            subSectionId,
            invitationData,
            isNewUser,
            handleClose,
          })
        );
      } else {
        const payload = [
          {
            user_id: userId?.invoffuserid,
            contact_id: userId?.contact?.id,
            contact_role: userId?.role_permission?.id,
            section_id: props.sectionId,
            subsection_id: props.subSectionId,
            user_email: userId?.email,
            first_name: userId?.first_name,
            last_name: userId?.last_name,
            company_name: userId?.company?.name,
          },
        ];
        dispatch(delegateUser({ payload, applicationId, handleClose }));
      }
    }
  };

  const RenderMenuItem = ({ items }) => {
    const roleName = items?.role_permission?.role_id?.role_name || "";
    const nameRole = `${items.first_name || ""} ${
      items.last_name || ""
    } (${roleName}) `;

    return <Typography>{nameRole}</Typography>;
  };

  const iconcolor = props.iconcolor;
  const iconcolorhover = props.hovercolor;

  const dialogTitle = "Delegate this section";
  const dialogContent = (
    <form id="hook-form">
      <Box>
        <Typography sx={{ fontWeight: "bold" }} component="p" variant="body2">
          You are about to share the following
        </Typography>
        <Typography component="p" variant="body2">
          <strong>Section:</strong>{" "}
          <span style={{ color: iconcolor }}>{props.sectionTitle}</span>
        </Typography>
        <Typography component="p" variant="body2">
          <strong>Sub Section:</strong>{" "}
          <span style={{ color: iconcolor }}>{props.subSectionTitle}</span>
        </Typography>
        {otherUsersExist === "yes" && (
          <FormControl component="fieldset" sx={{ mt: 2 }}>
            <Typography sx={{ color: props.iconcolor }} component="legend">
              Share with...
            </Typography>
            <RadioGroup
              row
              aria-label="User"
              value={userType}
              name="userType"
              onChange={handleRadioChange}
            >
              <FormControlLabel
                value="existing"
                control={<Radio />}
                label="Existing User"
              />
              <FormControlLabel
                value="new"
                control={<Radio />}
                label="New User"
              />
            </RadioGroup>
          </FormControl>
        )}
        {userType === "existing" && (
          <>
            <Typography component="p" variant="body2" sx={{ mt: 2, mb: 2 }}>
              Choose User to delegate this section to:
            </Typography>
            <Box sx={{ mb: 4 }}>
              <FormControl fullWidth>
                <InputLabel id="choose-user">Choose User *</InputLabel>
                <Select
                  label="Choose User"
                  labelId="choose-user"
                  name="userId"
                  required
                  // value={userData.userId}
                  // onChange={handleChange}
                  {...register("userId", {
                    required:
                      userType === "existing" && "This field is required",
                  })}
                >
                  {filterUserList?.map((items) => {
                    return (
                      <MenuItem key={items.id} value={items}>
                        <RenderMenuItem items={items} />
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <Typography fontSize="12px" color="red">
                {errors?.userId && errors?.userId?.message}
              </Typography>
            </Box>
          </>
        )}
        {userType === "new" && (
          <Box>
            {availableUsers > 0 ? (
              <>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "column", lg: "row" },
                  }}
                >
                  <Box sx={{ mt: 2, mb: 2, mr: 2, width: "100%" }}>
                    <TextField
                      labelId="firstNameLabelId"
                      hiddenLabel
                      name="firstName"
                      {...register("firstName", {
                        required: "This field is required",
                      })}
                      required
                      id="firstName"
                      placeholder="Enter First Name"
                      label="Enter First Name"
                    />
                    <Typography fontSize="12px" color="red">
                      {errors?.firstName && errors?.firstName?.message}
                    </Typography>
                  </Box>

                  <Box sx={{ mt: 2, mb: 2, width: "100%" }}>
                    <TextField
                      labelId="lastNameLabelId"
                      hiddenLabel
                      required
                      name="lastName"
                      id="lastName"
                      {...register("lastName", {
                        required: "This field is required",
                      })}
                      placeholder="Enter Last Name"
                      label="Enter Last Name"
                    />
                    <Typography fontSize="12px" color="red">
                      {errors?.lastName && errors?.lastName?.message}
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ mt: 2, mb: 2 }}>
                  <TextField
                    labelId="emailLabelId"
                    hiddenLabel
                    required
                    {...register("email", {
                      required: "This field is required",
                      pattern: {
                        value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                        message: "Invalid email format",
                      },
                    })}
                    fullWidth
                    name="email"
                    type="email"
                    id="email"
                    placeholder="Enter Email Address"
                    label="Enter Email Address"
                  />
                  <Typography fontSize="12px" color="red">
                    {errors?.email && errors?.email?.message}
                  </Typography>
                </Box>
                <Box>
                  <TextField
                    sx={{ mt: 2, mb: 2, width: "100%" }}
                    hiddenLabel
                    required
                    fullWidth
                    name="jobTitle"
                    {...register("jobTitle", {
                      required: "This field is required",
                    })}
                    id="jobTitle"
                    placeholder="What Is Their Job Title?"
                    label="Job Title"
                  />
                  <Typography fontSize="12px" color="red">
                    {errors?.jobTitle && errors?.jobTitle?.message}
                  </Typography>
                </Box>

                <FormControl fullWidth sx={{ mt: 2, mb: 2 }}>
                  <InputLabel id="userLevelLabel">User Level *</InputLabel>
                  <Select
                    label="Choose User"
                    labelId="userLevelLabel"
                    name="userLevel"
                    {...register("userLevel", {
                      required: "This field is required",
                    })}
                  >
                    {roleData?.map((data) => {
                      const roleDescription = data?.role_id?.description;
                      return (
                        <MenuItem key={data.id} value={data?.id}>
                          <DescriptionTooltip description={roleDescription}>
                            <Typography sx={{ width: "100%" }}>
                              {data.role_id?.role_name}
                            </Typography>
                          </DescriptionTooltip>
                        </MenuItem>
                      );
                    })}
                  </Select>
                  <Typography fontSize="12px" color="red">
                    {errors?.userLevel && errors?.userLevel?.message}
                  </Typography>
                </FormControl>
                <FormControl component="fieldset" sx={{ mt: 2 }}>
                  <FormLabel component="legend">
                    Add user access expiry date?
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-label="expiry date"
                    value={addExp}
                    name="addExpDate"
                    onChange={handleExpRadio}
                  >
                    <FormControlLabel
                      value="yes"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
                {addExp === "yes" && (
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    locale={enGB}
                  >
                    <DatePicker
                      value={expiryDate}
                      onChange={(newValue) => {
                        setExpiryDate(newValue);
                      }}
                      format="DD/MM/YYYY"
                      slotProps={{
                        popper: { placement: "top" },
                        // textField: {
                        //   InputProps: { disableUnderline: true },
                        //   variant: "standard",
                        // },
                      }}
                    />
                  </LocalizationProvider>
                )}
              </>
            ) : (
              <>
                <Typography
                  sx={{ fontWeight: "bold" }}
                  component="p"
                  variant="body"
                >
                  To request a new user please click complete the question below
                </Typography>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="todo"
                  label="How would you like your additional users to use the form?"
                  type="text"
                  id="todo"
                  sx={{ borderRadius: "50px", minWidth: "400px" }}
                  autoComplete="current-password"
                />
              </>
            )}
          </Box>
        )}
      </Box>
    </form>
  );

  return (
    <div>
      <Tooltip
        open={toolTipOpen}
        onClose={tooltipClose}
        onOpen={handleOpen}
        disableFocusListener
        title={canDelegateApplication ? "Delegate this section" : "Not Allowed"}
        arrow
      >
        <MyComponent iconcolor={iconcolor} iconcolorhover={iconcolorhover} />
      </Tooltip>

      <Dialog
        open={open}
        TransitionComponent={Transition}
        transitionDuration={{ enter: 1000, exit: 700 }}
        // TransitionProp={{duration:{ enter: 2000, exit: theme.transitions.duration.leavingScreen},easing:{enter: theme.transitions.easing.easeInOut, exit: theme.transitions.easing.easeInOut}}}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        style={{ padding: "30px" }}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle sx={{ textAlign: "center" }}>{dialogTitle}</DialogTitle>
        <DialogContent>{dialogContent}</DialogContent>
        <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
          <IconLabelButton
            variant="text"
            buttonColor={iconcolor}
            disableRipple={true}
            mylabel="Cancel"
            iconType="close"
            iconPosition="start"
            onClick={handleClose}
          />

          <IconLabelButton
            variant="contained"
            buttonColor={iconcolor}
            disableRipple={true}
            mylabel={
              availableUsers > 0 ? "Delegate to user" : "Request new users"
            }
            iconType={loading && "loading"}
            iconPosition="end"
            onClick={handleSubmit(onSubmit)}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
}
