import * as React from "react";
import { useDispatch } from "react-redux";
import {
  Popover,
  Avatar,
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Link,
  Typography,
} from "@mui/material";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import LogoutIcon from "@mui/icons-material/Logout";
import { logout } from "../actions/global";
import { useHistory } from "react-router-dom";
import { useFirstName, useLastName } from "../hooks/useLocalStorage";
import { getColorProfile } from "./getColorProfile";
export default function BasicPopover() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const dispatch = useDispatch();

  const profile_pic = sessionStorage.getItem("profile_pic");

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const history = useHistory();

  const firstName = useFirstName();
  const lastName = useLastName();
  const firstLetter = firstName?.[0]?.toUpperCase();
  const lastLetter = lastName?.[0]?.toUpperCase();
  const randomProfileBg = getColorProfile({ firstName, lastName });

  return (
    <Box sx={{ py: 0.5 }}>
      <Avatar
        aria-describedby={id}
        variant="contained"
        onClick={handleClick}
        alt={firstName}
        src={profile_pic !== "undefined" && `${profile_pic}`}
        sx={{ width: "40px", height: "40px", background: randomProfileBg }}
      >
        {firstLetter + lastLetter}
      </Avatar>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        elevation={4}
        PaperProps={{
          style: {
            borderRadius: "10px",
            marginTop: "20px",
          },
        }}
      >
        <Box sx={{ borderRadius: "30px" }}>
          <List>
            <ListItem
              onClick={() => history.push({ pathname: "/my-profile" })}
              disablePadding
            >
              <ListItemButton>
                <ListItemIcon>
                  <PermIdentityIcon />
                </ListItemIcon>
                <Typography underline="none">
                  <ListItemText primary="Edit My Profile" />
                </Typography>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={() => dispatch(logout())}>
                <ListItemIcon>
                  <LogoutIcon />
                </ListItemIcon>
                <Link underline="none">
                  <ListItemText primary="Logout" />
                </Link>
              </ListItemButton>
            </ListItem>
          </List>
        </Box>
      </Popover>
    </Box>
  );
}
