const initialState = {
  searched_companies: [],
  searched_persons: [],
  comapny_roles: [],
  company_by_id: {},
  user_detail: {},
  business_team_heads: [],
  business_experties: [],
  business_areaserved: [],
  business_products: [],
  business_services: [],
  regions: [],
  countries: [],
  ref_business_products: [],
  ref_business_industires: [],
  ref_business_services: [],

  user_status: {},
  suggestionInterestRes: {},
  saveSuggestionInterestRes: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case "GET_SEARCHED_COMPANIES":
      return {
        ...state,
        searched_companies: action.payload,
      };
    case "GET_SEARCHED_PERSONES":
      return {
        ...state,
        searched_persons: action.payload,
      };
    case "GET_COMPANY_BY_ID":
      return {
        ...state,
        company_by_id: action.payload,
      };

    case "GET_USER_DETAIL":
      return {
        ...state,
        user_detail: action.payload,
      };

    case "GET_COMPANY_ROLES":
      return {
        ...state,
        comapny_roles: action.payload,
      };

    case "BUSINESS_EXPERTISES":
      return {
        ...state,
        business_experties: action.payload,
      };

    case "BUSINESS_PRODUCTS":
      return {
        ...state,
        business_products: action.payload,
      };

    case "BUSINESS_SERVICES":
      return {
        ...state,
        business_services: action.payload,
      };

    case "BUSINESS_AREASERVED":
      return {
        ...state,
        business_areaserved: action.payload,
      };

    case "BUSINESS_TEAM_HEADS":
      return {
        ...state,
        business_team_heads: action.payload,
      };

    case "GET_REGIONS":
      return {
        ...state,
        regions: action.payload,
      };

    case "GET_COUNTRIES":
      return {
        ...state,
        countries: action.payload,
      };

    case "REF_BUSINESS_PRODUCTS":
      return {
        ...state,
        ref_business_products: action.payload,
      };
    case "REF_BUSINESS_INDUSTIRES":
      return {
        ...state,
        ref_business_industires: action.payload,
      };
    case "REF_BUSINESS_SERVICES":
      return {
        ...state,
        ref_business_services: action.payload,
      };

    // availability
    case "AVAIL_GET_MULTI_AVAIL_USER":
      return {
        ...state,
        user_status: action.payload,
      };
      case "GET_SUGGESTION_RES":
        return {
          ...state,
          suggestionInterestRes: action.payload,
        };
      case "SAVE_SUGGESTION_RES":
        return {
          ...state,
          saveSuggestionInterestRes: action.payload,
        };
  
    default:
      return state;
  }
}
