import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { hideSuccessError } from "./actions/common";
import { useSelector, useDispatch } from "react-redux";
const Toaster = () => {
  const [open, setOpen] = useState(false);
  const { showAlert, alertMessage, alertType } = useSelector(
    (store) => store.common
  );

  const dispatch = useDispatch();

  const handleClose = () => {
    setOpen(false);
    dispatch(hideSuccessError());
  };

  return (
    <div>
      {/* <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={this.state.open}
          autoHideDuration={autoHide}
          message={message}
          onClose={this.handleClose}
        /> */}
      {alertType === "success"
        ? toast.success(alertMessage, {
            toastId: " ",
            onClose: () => handleClose(),
          })
        : alertType === "error"
        ? toast.error(alertMessage, {
            toastId: " ",
            onClose: () => handleClose(),
          })
        : alertType === "warning"
        ? toast.warn(alertMessage, {
            toastId: " ",
            onClose: () => handleClose(),
          })
        : alertType === "warning" &&
          toast.info(alertMessage, {
            toastId: " ",
            onClose: () => handleClose(),
          })}

      {showAlert && (
        <ToastContainer
          position="top-center"
          autoClose={3000}
          pauseOnFocusLoss={false}
          onClose={handleClose}
        />
      )}
    </div>
  );
};

export default Toaster;
