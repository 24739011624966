import React, { useState, useEffect, useRef } from "react";

const HTMLRenderer = ({ src }) => {
  const [htmlContent, setHtmlContent] = useState("");
  const shadowRootRef = useRef(null);

  useEffect(() => {
    fetch(src)
      .then((response) => response.text())
      .then((html) => {
        setHtmlContent(html);
      });
  }, [src]);

  useEffect(() => {
    if (shadowRootRef?.current && htmlContent) {
      const shadowRoot = shadowRootRef?.current?.attachShadow({ mode: "open" });
      shadowRoot.innerHTML = htmlContent;
    }
  }, [htmlContent]);

  return <div ref={shadowRootRef} />;
};

export default HTMLRenderer;
