const initialState = {
  registeredUserList: {},
  party_details: [],
  party_business: [],
  party_business_without_p: [],
  regions: [],
  countries: [],
  states: [],
  cities: [],
  company_roles: [],
  businessTypes: [],
  primaryBuissness: [],
  secondryBuissness: [],
  party_business_response: {},
  business_location_data: {},
  update_location_response: {},
  location_delete_response: {},
  units_delete_response: {},
  subunits_delete_response: {},
  business_subunits_response: {},
  business_floors_response: {},
  business_team_response: {},
  team_delete_response: {},
  team_member_delete_response: {},
  business_team_member_response: {},
  business_department_response: {},
  department_delete_response: {},
  floor_delete_response: {},
  business_units_response: {},
  businesslocation_floors: [],
  businesslocation_floors_without_p: [],
  business_location_floor_units: [],
  business_location_floor_units_without_p: [],
  visibilities: [],
  departments: [],
  business_locations: [],
  business_location_by_id: [],
  floor_by_id: [],
  unit_by_id: [],
  subunits: [],
  subunits_without_p: [],
  business_location_floor_subunits: [],
  subunitsTypes: [],
  timeZones: [],
  business_location_departments_list: [],
  party_or_business_departments: [],
  party_or_business_departments_without: [],
  department_team_list: [],
  department_team_list_without: [],
  department_by_id: [],
  subunit_by_id: [],
  team_members_list: [],
  team_members_list_pagination: {},
  location_members_list: [],
  team_by_id: [],
  subunits_by_location: [],
  departments_by_location: [],
  party_person_roles: [],
  person_invoff_roles: [],
  team_member_by_id: [],
  currentPartyBuissness: null,
  currentParty: null,
  get_location_detail_by_id: [],
  get_departments_by_location: [],
  location_map: [],
  allRoles: [],
  currencyData: [],
  languageData: [],
  allModules: [],
  all_team_list: [],
  allUsers: [],
  refProfileVisibilityData: [],
  add_user_response: {},
  avilableSubunits: [],
  submitSeatResponse: {},

  bookingHomeTileResponse: {},
  bookinUserTrendResponse: {},
  seatAcceptResponse: {},
};

export default (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case "GET_REGISTERED_USERS":
      return {
        ...state,
        registeredUserList: payload,
      };
    case "GET_INTERNAL_PARTY":
      return {
        ...state,
        party_details: payload,
      };
    case "GET_INTERNAL_PARTY_BUSINESS":
      return {
        ...state,
        party_business: payload,
      };
    case "GET_INTERNAL_PARTY_BUSINESS_WITHOUT_P":
      return {
        ...state,
        party_business_without_p: payload,
      };

    case "UPDATE_PARTY_BUSINESS":
      return {
        ...state,
        party_business_response: payload,
      };
    case "GET_A_PARTY_BY_ID":
      return {
        ...state,
        currentParty: payload,
      };

    case "GET_A_PARTYBUISSNESS_BY_ID":
      return {
        ...state,
        currentPartyBuissness: payload,
      };

    case "GET_BUISSNESS_TYPES":
      return {
        ...state,
        businessTypes: payload,
      };
    case "GET_PRIMARY_BUISSNESS_TYPES":
      return {
        ...state,
        primaryBuissness: payload,
      };
    case "GET_SECONDRY_BUISSNESS_TYPES":
      return {
        ...state,
        secondryBuissness: payload,
      };
    case "GET_ALL_COUNTRIES":
      return {
        ...state,
        countries: payload,
      };

    case "GET_ALL_CITIES":
      return {
        ...state,
        cities: payload,
      };

    case "GET_COMPANY_ROLES":
      return {
        ...state,
        company_roles: payload,
      };
    case "GET_CURRENCIES":
      return {
        ...state,
        currencyData: payload.data,
      };
    case "GET_LANGUAGES":
      return {
        ...state,
        languageData: payload.data,
      };
    case "ADD_UPDATE_BUSINESS_LOCATIONS":
      return {
        ...state,
        business_location_data: payload,
      };

    case "ADD_BUSINESS_TEAM":
      sessionStorage.setItem("user_team_id", payload.user_team_id);
      return {
        ...state,
        business_team_response: payload,
      };

    case "DELETE_BUSINESS_LOCATIONS":
      return {
        ...state,
        location_delete_response: payload,
      };

    case "DELETE_BUSINESS_UNIT":
      return {
        ...state,
        units_delete_response: payload,
      };

    case "DELETE_BUSINESS_SUBUNIT":
      return {
        ...state,
        subunits_delete_response: payload,
      };
    case "DELETE_BUSINESS_DEPARTMENT":
      return {
        ...state,
        department_delete_response: payload,
      };
    case "ADD_FLOORS":
      return {
        ...state,
        business_floors_response: payload,
      };
    case "DELETE_BUSINESS_FLOOR":
      return {
        ...state,
        floor_delete_response: payload,
      };

    case "DELETE_BUSINESS_TEAM":
      return {
        ...state,
        team_delete_response: payload,
      };

    case "DELETE_TEAM_MEMBER":
      return {
        ...state,
        team_member_delete_response: payload,
      };
    case "ADD_TEAM_MEMBER":
      return {
        ...state,
        business_team_member_response: payload,
      };

    case "GET_LOCATION_FLOORS":
      return {
        ...state,
        businesslocation_floors: payload,
      };
    case "GET_LOCATION_FLOORS_WITHOUT_P":
      return {
        ...state,
        businesslocation_floors_without_p: payload,
      };

    case "Add_BUSINESS_FLOOR_UNIT":
      return {
        ...state,
        business_units_response: payload,
      };

    case "ADD_LOCATION_FLOORS_UNITS_SUBUNIT":
      return {
        ...state,
        business_subunits_response: payload,
      };

    case "GET_LOCATION_FLOORS_UNIT":
      return {
        ...state,
        business_location_floor_units: payload,
      };
    case "GET_LOCATION_FLOORS_UNIT_WITHOUT_P":
      return {
        ...state,
        business_location_floor_units_without_p: payload,
      };

    case "GET_VISIBILITY":
      return {
        ...state,
        visibilities: payload,
      };
    case "GET_DEPARTMENT":
      return {
        ...state,
        departments: payload,
      };

    case "GET_PARTY_OR_BUSINESS_DEPARTMENT":
      return {
        ...state,
        party_or_business_departments: payload,
      };
    case "GET_PARTY_OR_BUSINESS_DEPARTMENT_WITHOUT_P":
      return {
        ...state,
        party_or_business_departments_without: payload,
      };
    case "ADD_BUSINESS_DEPARTMENT":
      return {
        ...state,
        business_department_response: payload,
      };
    case "GET_BUSINESS_LOCATIONS":
      return {
        ...state,
        business_locations: payload,
      };
    case "GET_BUSINESS_LOCATIONS_WITHOUT_PAGINATION":
      return {
        ...state,
        business_locations_without_p: payload,
      };

    case "GET_BUSINESS_LOCATION_BY_ID":
      return {
        ...state,
        business_location_by_id: payload,
      };

    case "GET_FLOOR_BY_ID":
      return {
        ...state,
        floor_by_id: payload,
      };

    case "GET_UNIT_BY_ID":
      return {
        ...state,
        unit_by_id: payload,
      };

    case "GET_SUBUNITS":
      return {
        ...state,
        subunits: payload,
      };
    case "GET_SUBUNITS_WITHOUT_P":
      return {
        ...state,
        subunits_without_p: payload,
      };
    case "GET_FLOOR_SUBUNITS":
      return {
        ...state,
        business_location_floor_subunits: payload,
      };
    case "GET_SUBUNIT_TYPES":
      return {
        ...state,
        subunitsTypes: payload,
      };
    case "GET_TIME_ZONES":
      return {
        ...state,
        timeZones: payload,
      };
    case "GET_BUSINESS_LOCATION_DEPARTMENTS":
      return {
        ...state,
        business_location_departments_list: payload,
      };

    case "GET_DEPARTMENT_TEAMS":
      return {
        ...state,
        department_team_list: payload,
      };
    case "GET_DEPARTMENT_TEAMS_WITHOUT_P":
      return {
        ...state,
        department_team_list_without: payload,
      };

    case "GET_ALL_TEAM_MEMBER":
      return {
        ...state,
        all_team_list: payload,
      };

    case "GET_DEPARTMENT_BY_ID":
      return {
        ...state,
        department_by_id: payload,
      };

    case "GET_SUBUNIT_BY_ID":
      return {
        ...state,
        subunit_by_id: payload,
      };

    case "GET_LOCATION_MEMBERS_LIST":
      return {
        ...state,
        location_members_list: payload,
      };

    case "GET_TEAM_MEMBERS_LIST":
      return {
        ...state,
        team_members_list: payload,
      };
    case "GET_TEAM_MEMBERS_LIST_PAGINATION":
      return {
        ...state,
        team_members_list_pagination: payload,
      };

    case "GET_TEAM_BY_ID":
      return {
        ...state,
        team_by_id: payload,
      };

    case "GET_SUBUNITS_BY_LOCATION_ID":
      return {
        ...state,
        subunits_by_location: payload,
      };

    case "GET_DEPARTMENTS_BY_LOCATION_ID":
      return {
        ...state,
        departments_by_location: payload,
      };

    case "GET_PERSON_INVOFF_ROLES":
      return {
        ...state,
        person_invoff_roles: payload,
      };

    case "GET_PARTY_PERSON_ROLES":
      return {
        ...state,
        party_person_roles: payload,
      };

    case "GET_TEAM_MEMBER_BY_ID":
      return {
        ...state,
        team_member_by_id: payload,
      };

    case "GET_COUNTRY_STATE":
      return {
        ...state,
        states: payload,
      };

    case "GET_ALL_REGIONS":
      return {
        ...state,
        regions: payload,
      };

    // Business Details Reducer
    case "GET_LOCATION_DETAIL_BY_ID":
      return {
        ...state,
        get_location_detail_by_id: payload,
      };

    case "GET_LOCATION_MAP":
      return {
        ...state,
        location_map: payload,
      };

    case "GET_ROLES":
      return {
        ...state,
        allRoles: payload,
      };

    case "GET_MODULES":
      return {
        ...state,
        allModules: payload,
      };

    case "ADD_INVOFF_USER":
      return {
        ...state,
        add_user_response: payload,
      };
    case "GET_ALL_USERS":
      return {
        ...state,
        allUsers: action.payload,
      };
    case "GET_REF_PROFILE_DATA":
      return {
        ...state,
        refProfileVisibilityData: action.payload,
      };
    case "GET_AVAILABLE_SUBUNITS":
      return {
        ...state,
        avilableSubunits: action.payload,
      };
    case "SUBMIT_SEAT":
      return {
        ...state,
        submitSeatResponse: action.payload,
      };
    case "BOOKING_HOME_TILE":
      return {
        ...state,
        bookingHomeTileResponse: action.payload,
      };
    case "BOOKING_USER_TREND":
      return {
        ...state,
        bookinUserTrendResponse: action.payload,
      };
    case "SEAT_ACCEPT":
      return {
        ...state,
        seatAcceptResponse: action.payload,
      };
    default:
      return state;
  }
};
