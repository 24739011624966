import React from "react";
import { styled } from "@mui/material/styles";
import { Typography, Button } from "@mui/material";
import { useHistory } from "react-router-dom";

const RootContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  minHeight: "100vh",
  maxWidth: "1224px",
  margin: "auto",
  color: "#333",
});

const Heading = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  fontWeight: 700,
  fontSize: "2.5rem",
}));

const Description = styled(Typography)(({ theme }) => ({
  margin: "1rem!important",
  marginBottom: theme.spacing(4),
  fontSize: "1.2rem",
}));

const CustomButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#fff",
  color: "#ff4081",
  padding: theme.spacing(1.5, 4),
  borderRadius: theme.spacing(2),
  fontWeight: 600,
  textTransform: "capitalize",
  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.3)",
  "&:hover": {
    backgroundColor: "#f1f1f1",
  },
}));

const PageNotFound = () => {
  const history = useHistory();

  return (
    <RootContainer>
      <Heading variant="h1">Page not found</Heading>
      <Description variant="h4">
        Oops! It looks like the page you're looking for doesn't exist or may
        have been moved. Click the button to go to your Dashboard, or contact us
        on support@barerock.group for help
      </Description>
      <CustomButton
        variant="contained"
        onClick={() => {
          history.push("/");
        }}
      >
        Go To Dashboard
      </CustomButton>
    </RootContainer>
  );
};

export default PageNotFound;
