import React from "react";
import { styled } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { useTheme } from "@mui/material/styles";
let progressColor;
export default function ProgressBar(props) {
  const theme = useTheme();
  // const perc = React.useState(props.percentage);
  // if (perc < 33) {
  // 	progressColor = 'red';
  // } else if (perc < 66) {
  // 	progressColor = 'orange';
  // } else if (perc <= 100) {
  // 	progressColor = 'green';
  // }
  if (props.color === "people") {
    progressColor = theme?.palette?.people?.main;
  } else if (props.color === "process") {
    progressColor = theme?.palette?.process.main;
  } else if (props.color === "products") {
    progressColor = theme?.palette?.products.main;
  }
  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: "#EDEDED",
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: props.color,
    },
  }));

  return (
    <BorderLinearProgress
      sx={{
        maxWidth: { xl: "75%", xs: "87%" },
      }}
      variant="determinate"
      value={props.percentage}
    />
  );
}
