import { useSelector } from "react-redux";
import Typography from "@mui/material/Typography";
const WelcomeName = () => {
  const { loggedUserData } = useSelector((store) => store.auth);
  const name = loggedUserData?.user_data?.first_name || "";

  return (
    <Typography
      align="center"
      variant="body2"
      sx={{
        padding: "20px 0",
        fontSize: "1.2rem",
        whiteSpace: "normal",
        fontWeight: "400",
      }}
    >
      Welcome, {!name ? "Guest" : name}
    </Typography>
  );
};

export default WelcomeName;
