import { Box, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { motion } from "framer-motion";

const CustomDrawer = ({ width, open, setOpen, children, fromFirstTime }) => {
  return (
    <motion.div
      initial={{ x: "-100%" }}
      animate={{ x: open ? (fromFirstTime ? "35%" : "0%") : "-100%" }}
      transition={{ duration: 0.5, ease: "easeInOut" }}
      style={{
        minHeight: "100vh",
        background: "white",
        zIndex: "100",
        boxShadow: "10px 10px 24px -10px rgba(0,0,0,0.17)",
        paddingTop: 30,
        paddingBottom: 30,
        paddingLeft: 30,
        paddingRight: 10,
        position: "absolute",
        width: width ?? "350px",
      }}
    >
      <Box>
        <Box sx={{ display: "flex", justifyContent: "end" }}>
          <IconButton
            onClick={() => {
              setOpen(false);
            }}
          >
            <CloseIcon sx={{ height: 35, width: 35 }} />
          </IconButton>
        </Box>
        {children}
      </Box>
    </motion.div>
  );
};

export default CustomDrawer;
