const initialState = {
  stickyNotesData: {},
  notifications: [],
  notificationCount: 0,
  userActivities: {},
  search_global_data: {},
  businessInvitiesList: [],
  readNotiRes: {},
  chatWindow: false,
  chatSelectedView: "All_Messages",
  userOnlineStatus: {},
  chatMsgStat: {},
  onSocketHit: {},
  parentCompanyDetail: {},
};

const globalReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_STICKY_NOTES":
      return {
        ...state,
        stickyNotesData: action.payload.data,
      };

    case "OPEN_CHAT_SUCESS":
      return {
        ...state,
        chatWindow: action.payload.flag,
        chatSelectedView: action.payload.selectedView,
      };
    case "USER_ON_STATUS":
      return {
        ...state,
        userOnlineStatus: action.payload.data,
      };
    case "CHAT_MSG_STAT":
      return {
        ...state,
        chatMsgStat: action.payload,
      };
    case "API_CALL_ON_SOCKET_HIT":
      return {
        ...state,
        onSocketHit: action.payload,
      };
    case "GET_NOTIFICATIONS":
      return {
        ...state,
        notifications: action.payload.data,
        notificationCount: action.payload.count,
      };
    case "READ_NOTIFICATION":
      return {
        ...state,
        readNotiRes: action.payload.data,
      };
    case "SEARCH_DATA":
      return {
        ...state,
        search_global_data: action.payload,
      };
    case "USER_ACTIVITY":
      return {
        ...state,
        userActivities: action.payload,
      };
    case "BUSINESS_INVITIES":
      return {
        ...state,
        businessInvitiesList: action.payload,
      };
    case "PARENT_COMPANY":
      return {
        ...state,
        parentCompanyDetail: action.payload,
      };

    default:
      return state;
  }
};

export default globalReducer;
