import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import MainListItems from "./ListItems";
import ContactBox from "./ContactBox";
import Logo from "./Logo";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import CustomDrawer from "./customDrawer";
import ApplicationResources from "./applicationResources";
import WelcomeName from "./Welcome";
import MenuIcon from "@mui/icons-material/Menu";
import { IconButton, useMediaQuery } from "@mui/material";
import { useDispatch } from "react-redux";
import { showResources } from "../../../actions/insurance";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";

const drawerWidth = 300;

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open, mobileWidth }) => {
  const isRelative = !mobileWidth || !open;
  return {
    "& .MuiDrawer-paper": {
      backgroundColor: "white",
      boxShadow: "5px 5px 24px -10px rgba(0,0,0,0.17)",
      position: isRelative && "relative",
      whiteSpace: "nowrap",
      overflow: "visible",
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: "border-box",
      ...(!open && {
        overflowX: "none",
        transition: theme.transitions.create("width", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(2),
        [theme.breakpoints.up("sm")]: {
          width: theme.spacing(11),
        },
      }),
    },
  };
});

export default function Sidebar() {
  const [open, setOpen] = useState(true);
  const mobileWidth = useMediaQuery("(max-width: 850px)");

  const location = useLocation();

  const isApplicationIncludesInUrl =
    location?.pathname.includes("/application");

  const [openResources, setOpenResources] = useState(false);
  const handleOpenResources = () => {
    setOpenResources(true);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(showResources(openResources));
  }, [openResources]);

  const handleMenu = () => {
    setOpen(!open);
  };

  const onClickAway = () => {
    mobileWidth && setOpen(false);
  };

  useEffect(() => {
    mobileWidth && setOpen(false);
  }, [mobileWidth]);

  const bgColor = isApplicationIncludesInUrl ? "#F2F1F8" : "#fff";

  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <Drawer
        elevation={3}
        mobileWidth={mobileWidth}
        variant="permanent"
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <Box sx={{ position: "sticky", top: 0 }}>
          <Box sx={{ position: "absolute", right: "-3rem", top: 1 }}>
            <IconButton onClick={handleMenu}>
              <MenuIcon />
            </IconButton>
          </Box>
        </Box>
        <Box
          sx={{
            position: "sticky",
            top: 0,
            padding: 3,
            height: "100vh",
            overflow: "auto",
            background: bgColor,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Logo sx={{ padding: "20px" }} />
            {open && <WelcomeName />}
            {open && (
              <Divider
                variant="middle"
                sx={{
                  borderTopWidth: "1px",
                  borderBottomWidth: "0",
                  borderColor: "#cacbe2",
                }}
              />
            )}

            <MainListItems
              open={open}
              handleOpenResources={handleOpenResources}
            />
          </Box>
          <ContactBox bottom={30} left={30} />
        </Box>
        {/* drawer for applicaiton resources */}
        {openResources && (
          <CustomDrawer open={openResources} setOpen={setOpenResources}>
            <ApplicationResources />
          </CustomDrawer>
        )}
      </Drawer>
    </ClickAwayListener>
  );
}
