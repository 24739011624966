import {
  Box,
  Divider,
  IconButton,
  Typography,
  CircularProgress,
} from "@mui/material";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";
import { useState } from "react";
import { hasHttps } from "../../../../utils/checkImageUrl";
import { baseURL } from "../../../../config";

const Guide = ({ applicationResources, systemGuides }) => {
  const guideItems = applicationResources?.data?.guides?.map((item) => {
    return {
      header: `Download '${item.section_title}' Guide`,
      url: item.training_guide,
      sectionTitle: item?.section_title,
    };
  });

  const otherGuideItems = systemGuides?.map((item) => {
    return {
      header: item?.name,
      url: item.document,
    };
  });

  const hasOtherGuides = otherGuideItems?.length > 0;
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [downloadIndex, setDownloadIndex] = useState(null);

  const onDownloadClick = ({ url, index, sectionTitle }) => {
    setDownloadIndex(index);
    if (!downloadLoading) {
      setDownloadLoading(true);
      fetch(url)
        .then((response) => {
          response.blob().then((blob) => {
            const fileURL = window.URL.createObjectURL(blob);
            let alink = document.createElement("a");
            alink.href = fileURL;
            alink.download = sectionTitle ?? "download";
            alink.click();
            setDownloadLoading(false);
          });
        })
        .catch((err) => {
          setDownloadLoading(false);
        });
    }
  };

  const renderBox = ({ item, index, isOther }) => {
    return (
      <Box key={index}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mt: 3,
            alignItems: "center",
            cursor: "pointer",
          }}
          onClick={() => {
            onDownloadClick({
              url: hasHttps(item.url) ? item.url : baseURL + item.url,
              index,
              sectionTitle: item?.sectionTitle ?? item?.header,
            });
          }}
        >
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: "500",
              color: "#4C4954",
              textDecoration: "underline",
              textWrap: "wrap",
              whiteSpace: "normal",
            }}
          >
            {item.header}
          </Typography>
          {downloadIndex === index && downloadLoading ? (
            <CircularProgress sx={{ color: "#333" }} size="23px" />
          ) : (
            <IconButton>
              <SystemUpdateAltIcon />
            </IconButton>
          )}
        </Box>
        <Divider sx={{ mt: 1 }} />
      </Box>
    );
  };

  return (
    <Box>
      <Typography
        sx={{
          mt: 4,
          fontWeight: "bold",
          fontSize: "14px",
          textWrap: "wrap",
          whiteSpace: "normal",
        }}
      >
        Application Resources
      </Typography>
      {guideItems?.map(
        (item, index) => item?.url && renderBox({ item, index })
      )}
      {hasOtherGuides && (
        <Typography
          sx={{
            mt: 4,
            fontWeight: "bold",
            fontSize: "14px",
            textWrap: "wrap",
            whiteSpace: "normal",
          }}
        >
          Other Resources
        </Typography>
      )}
      {otherGuideItems?.map(
        (item, index) =>
          item?.url &&
          renderBox({ item, index: `other${index}`, isOther: true })
      )}
    </Box>
  );
};
export default Guide;
