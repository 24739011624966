import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";

import Drawer from "@mui/material/Drawer";
import { Divider, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import Pen from "../../presentation/icons/Pen";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import React from "react";
import moment from "moment";
import { updateNotification } from "../../../actions/global";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function NotificationDrawer({
  drawerState,
  toggleNotificationDrawer,
}) {
  const { notifications, notificationCount } = useSelector(
    (store) => store.global
  );

  const userDetails = JSON.parse(sessionStorage.getItem("user"));
  const invoffuserid = userDetails?.invoffuserid;
  const dispatch = useDispatch();

  const handleReadAll = () => {
    const value = {
      user: invoffuserid,
      read_all: true,
    };
    dispatch(updateNotification(value));
  };

  const history = useHistory();

  const handleNotificationSeenClick = (data) => {
    const value = {
      user: invoffuserid,
      notification_id: data.id,
    };
    dispatch(updateNotification(value));
    let pathname, state;
    switch (data.module_name) {
      case "Application":
        pathname = `/my-applications`;
        break;
      case "Policy":
        pathname = `/policy/${data.record_id}`;
        state = { policyData: data.record_id };
        break;
      case "Quote":
        pathname = `/my-applications`;
        state = { quoteData: data.record_id };
        break;
      case "Contact":
        pathname = "/my-profile";
        state = { companyId: data.record_id };
        break;
      default: {
        /* empty */
      }
    }

    history.push({
      key: data.record_id,
      pathname: pathname,
      state: state,
    });
  };

  const renderIconByFilterName = (moduleName) => {
    switch (moduleName) {
      case "Application":
      case "Quote":
        return <Pen color="palette.text" />;
      case "Contact":
      case "Users":
        return <PersonOutlineOutlinedIcon />;
      case "Policy":
        return <DescriptionOutlinedIcon />;
      default:
        return <></>;
    }
  };

  return (
    <div style={{ width: "440px" }}>
      <React.Fragment key="right">
        <Drawer
          PaperProps={{
            // square: false,
            sx: {
              height: "60%",
              borderRadius: "20px",
              marginRight: 2,
              marginTop: 2,
              // overflow: "hidden",
              // overflowX: "hidden",
              // overflowY: "scroll",
              // wordBreak: "break-all",
            },
          }}
          anchor="right"
          open={drawerState}
          onClose={() => toggleNotificationDrawer(false)}
        >
          <Box>
            <Stack
              sx={{
                background: "#9309FE",
                p: 1,
                position: "sticky",
                top: 0,
                zIndex: 1,
                overflowY: "scroll",
              }}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Stack direction="row" gap={2}>
                <Typography fontWeight="600" fontSize="1.2rem" color={"#fff"}>
                  Notification
                </Typography>
                <Box
                  style={{
                    borderRadius: "50%",
                    border: `1px solid #526EFF `,
                    background: "#526EFF",
                    height: 30,
                    width: 30,
                  }}
                >
                  <Typography
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                      padding: "2px 8px",
                    }}
                    fontWeight="600"
                    // fontSize={theme?.setPageFontSize?.paragraph}
                    color={"#fff"}
                  >
                    {notificationCount?.unread || 0}
                  </Typography>
                </Box>
              </Stack>
              {/* <Stack direction="row" gap={2} alignItems="center">
                <Link className="link" to="/notification">
                  <Chip
                    style={{
                      color: "white",
                      cursor: "pointer",
                      fontWeight: "700",
                    }}
                    label="View All"
                    variant="outlined"
                    onClick={() => toggleNotificationDrawer(false)}
                  />
                </Link>
              </Stack> */}
            </Stack>

            <Divider />

            {/* last 24 hours */}

            <Box
              sx={{
                width: "400px",
                overflowX: "hidden",
              }}
            >
              <Box style={{ padding: "10px" }}>
                {notifications?.count?.unread !== 0 && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "end",
                    }}
                  >
                    <Typography
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        handleReadAll();
                      }}
                      fontWeight={700}
                      fontSize="13px"
                      color={"#526EFF"}
                    >
                      Mark all as read
                    </Typography>
                  </Box>
                )}

                <Typography
                  fontWeight="600"
                  style={{ wordWrap: "break-word" }}
                  // color={theme?.colors?.textheadercolor}
                  // fontSize={theme?.setPageFontSize?.headingseven}
                  // fontFamily={theme?.fontBoldFamily}
                >
                  Last 24 hours
                </Typography>
              </Box>

              {notifications
                ?.filter(
                  (data) =>
                    moment().diff(moment(data?.created_on), "hours") < 24
                )
                .map((filteredData, id) => {
                  return (
                    <Box key={id}>
                      <Divider style={{ width: "200%" }} />
                      <Box
                        sx={{ cursor: "pointer" }}
                        paddingX={2}
                        paddingY={1}
                        key={id}
                        onClick={() =>
                          handleNotificationSeenClick(filteredData)
                        }
                      >
                        {/* <NotificationIcon
                          moduleName={filteredData?.module_name}
                          seen={filteredData?.seen}
                          message={filteredData?.message}
                          createdOn={filteredData?.created_on}
                        /> */}
                        <Stack direction="row" gap={2} alignItems="center">
                          <Box
                            style={{
                              height: "30px",
                              width: "30px",
                              border: `1px solid #9309FE `,
                              borderRadius: "50%",
                              flexShrink: 0,
                            }}
                          >
                            <Box
                              style={{
                                flexShrink: 0,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "100%",
                              }}
                            >
                              {renderIconByFilterName(
                                filteredData?.module_name
                              )}
                            </Box>
                          </Box>
                          <Stack gap={1}>
                            <Typography
                              fontWeight={"900"}
                              // style={{ wordWrap: "break-word" }}
                              color={"rgb(76, 73, 84)"}
                              fontSize={"0.8rem"}
                            >
                              {filteredData?.message}
                            </Typography>
                            <Stack direction="row" gap={2} alignItems="center">
                              <Typography
                                fontWeight="400"
                                style={{ wordWrap: "break-word" }}
                                fontSize={"0.8em"}
                              >
                                {moment(filteredData?.created_on)
                                  .utc()
                                  .format("DD-MM-YYYY hh:mm:ss a")}
                              </Typography>
                              {filteredData?.seen === false && (
                                <Box
                                  sx={{
                                    height: 8,
                                    width: 8,
                                    background: "#526EFF",
                                    borderRadius: "50%",
                                  }}
                                ></Box>
                              )}
                            </Stack>
                          </Stack>
                        </Stack>
                      </Box>
                    </Box>
                  );
                })}

              {/* earlier */}

              <Box style={{ padding: "10px" }}>
                <Typography
                  fontWeight="600"
                  style={{ wordWrap: "break-word" }}
                  // color={theme?.colors?.textheadercolor}
                  // fontSize={theme?.setPageFontSize?.headingseven}
                  // fontFamily={theme?.fontBoldFamily}
                >
                  Earlier
                </Typography>
              </Box>

              {notifications
                ?.filter(
                  (data) =>
                    moment().diff(moment(data?.created_on), "hours") > 24
                )
                .map((filteredData, id) => {
                  return (
                    <Box key={id}>
                      <Divider sx={{ width: "200%" }} />
                      <Box
                        sx={{ cursor: "pointer" }}
                        paddingY={1}
                        paddingX={2}
                        key={id}
                        onClick={() =>
                          handleNotificationSeenClick(filteredData)
                        }
                      >
                        {/* <NotificationIcon
                          moduleName={filteredData?.module_name}
                          seen={filteredData?.seen}
                          message={filteredData?.message}
                          createdOn={filteredData?.created_on}
                        /> */}
                        <Stack direction="row" gap={2} alignItems="center">
                          <Box
                            style={{
                              height: "30px",
                              width: "30px",
                              border: `1px solid #9309FE `,
                              borderRadius: "50%",
                              flexShrink: 0,
                            }}
                          >
                            <Box
                              style={{
                                flexShrink: 0,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "100%",
                              }}
                            >
                              {renderIconByFilterName(
                                filteredData?.module_name
                              )}
                            </Box>
                          </Box>
                          <Stack gap={1}>
                            <Typography
                              fontWeight={"900"}
                              // style={{ wordWrap: "break-word" }}
                              color={"rgb(76, 73, 84)"}
                              fontSize="0.8rem"
                              // fontSize={theme?.setPageFontSize?.leading}
                            >
                              {filteredData?.message}
                            </Typography>
                            <Stack direction="row" gap={2} alignItems="center">
                              <Typography
                                fontWeight="400"
                                style={{ wordWrap: "break-word" }}
                                // color={theme?.colors?.textcolor}
                                fontSize={"0.8em"}
                              >
                                {moment(filteredData?.created_on)
                                  .utc()
                                  .format("DD-MM-YYYY hh:mm:ss a")}
                              </Typography>
                              {filteredData?.seen === false && (
                                <Box
                                  sx={{
                                    height: 8,
                                    width: 8,
                                    background: "#526EFF",
                                    borderRadius: "50%",
                                  }}
                                ></Box>
                              )}
                            </Stack>
                          </Stack>
                        </Stack>
                      </Box>
                    </Box>
                  );
                })}
            </Box>

            {/* <Link className="link" to="/notification">
              <Typography
                onClick={() => toggleNotificationDrawer(false)}
                sx={{ textAlign: "center", pb: 1, fontWeight: "700" }}
                color="blue"
              >
                View All
              </Typography>
            </Link> */}
            <Divider />
          </Box>
        </Drawer>
      </React.Fragment>
    </div>
  );
}
