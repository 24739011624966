import React from 'react';
import { SvgIcon } from '@mui/material';
export default function EditingUser(props) {
	return (
		<SvgIcon {...props} viewBox="0 0 543.53 371.11">
			<path d="M446.55,27.89a32.34,32.34,0,0,1,47.51,0l25.56,27a36.91,36.91,0,0,1,0,50.2L332,303.36a58.07,58.07,0,0,1-24.48,15.34L235,341.2c-3.37.51-7,.13-9.5-2.54a10.47,10.47,0,0,1-2.42-10L244.4,252a62,62,0,0,1,14.52-25.86ZM480.5,42.24a13.88,13.88,0,0,0-20.39,0L427.23,77l45.95,48,32.88-34.16a15.81,15.81,0,0,0,0-21.53ZM288.42,272.18l38.27,8.11,132.4-140.45L413.67,91.29,280.76,231.74ZM265,251.77a41.85,41.85,0,0,0-2.15,5.89l-16.38,58.88,55.65-17.3a40,40,0,0,0,5.64-2.28l-29.36-6.21a9.92,9.92,0,0,1-7.53-8Z" />
			<path d="M72.82,328.41a19.6,19.6,0,1,1-19.6-19.6A19.6,19.6,0,0,1,72.82,328.41Zm56,0a19.6,19.6,0,1,1-19.6-19.6A19.61,19.61,0,0,1,128.82,328.41Zm16.8,0A19.6,19.6,0,1,1,165.21,348,19.61,19.61,0,0,1,145.62,328.41Z" />
		</SvgIcon>
	);
}
