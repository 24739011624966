const initialState = {
  allCases: [],
  case_by_id: {},
  docUploadRes: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case "GET_ALL_CASES":
      return {
        ...state,
        allCases: action.payload.data,
      };
    case "GET_CASE_BY_ID":
      return {
        ...state,
        case_by_id: action.payload.data,
      };
    case "CASE_DOC_UPLOADED":
      return {
        ...state,
        docUploadRes: action.payload.data,
      };

    default:
      return state;
  }
}
