import { red } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";

let theme = createTheme({
  "&.fade-enter": {
    opacity: 0,
    zIndex: 1,
  },

  "&.fade-enter.fade-enter-active": {
    opacity: 1,
    transition: "opacity 250ms ease-in",
  },

  palette: {
    primary: {
      main: "#9308FF",
      light: "#cb52ff",
      dark: "#5900ca",
      contrastText: "#fff",
      superLight: "#fcfaff",
      danger: "#E00606",
      grey: "#999997",
    },
    secondary: {
      main: "#0CDEFF",
      light: "#6fffff",
      dark: "#00accc",
      contrastText: "#000000",
    },
    background: {
      default: "#fff",
    },
    blue: {
      main: "#008DFF",
      contrastText: "#fff",
    },
    white: {
      main: "#ffffff",
      contrastText: "#000000",
    },

    // #e5e5f8

    // people: {
    //   main: "#0CDEFF",
    //   hover: "#00accc",
    //   contrastText: "#fff",
    // },
    // process: {
    //   main: "#516dff",
    //   hover: "#3654B7",
    //   contrastText: "#fff",
    // },
    // products: {
    //   main: "#c211ff",
    //   hover: "#9707ff",
    //   contrastText: "#fff",
    // },

    text: {
      primary: "#000000",
    },
    error: {
      main: red.A400,
    },
  },
  typography: {
    overrides: {
      ".MuiLink-root": {
        textDecoration: "none",
      },
    },
    button: {
      textTransform: "none",
    },
    body1: {
      fontSize: "1rem",
      strong: {
        fontWeight: 500,
      },
    },
    body2: {
      fontSize: "1rem",
      strong: {
        fontWeight: 500,
      },
    },

    fontFamily: ["stolzl, Georgia", "Georgia"].join(","),

    fontSize: 13,
    p: {
      fontSize: "1rem",
    },
    td: {
      fontSize: "1rem",
    },
    th: {
      fontSize: "1rem",
    },
    h1: {
      fontSize: "2.4rem",
    },
    h3: {
      fontSize: "1.4rem",
    },
    h4: {
      fontSize: "1.3rem",
    },
    h5: {
      fontSize: "1.2rem",
    },

    "& .bolden": { fontWeight: "600" },
    h6: {
      fontSize: "1.1rem",
    },
    a: {
      transition: "all 0.5s ease-out",
      color: "text.primary",
      "&:hover": {
        transition: "all 0.5s ease-out",
        cursor: "pointer",
      },
    },
  },
  transitions: {
    duration: {
      shortest: 150,
      shorter: 200,
      short: 250,
      standard: 300,
      complex: 375,
      enteringScreen: 225,
      leavingScreen: 195,
    },
    easing: {
      easeInOut: "cubic-bezier(0.4, 0, 0.2, 1)",
      easeOut: "cubic-bezier(0.0, 0, 0.2, 1)",
      easeIn: "cubic-bezier(0.4, 0, 1, 1)",
      sharp: "cubic-bezier(0.4, 0, 0.6, 1)",
    },
  },
});

theme = createTheme(theme, {
  shadows: [
    "0", //elevation 0
    "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px", //elevation 1
    "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)", //elevation 2
    "0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)", //elevation 3
    "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)", //elevation 4
    "0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)", //elevation 5
    "0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)", //elevation 6
    "0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22)", //elevation 7
    "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
  ],
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        MuiBox: {
          scrollbarColor: "#6b6b6b #2b2b2b",
          "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
            backgroundColor: "#f2f2f9",
          },
          "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
            borderRadius: 8,
            backgroundColor: theme.palette.primary.dark,
            minHeight: 24,
            //border: '3px solid #2b2b2b'
          },
          "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus":
            {
              backgroundColor: "#f2f2f9",
            },
          "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active":
            {
              backgroundColor: "#f2f2f9",
            },
          "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover":
            {
              backgroundColor: theme.palette.primary.main,
            },
          "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
            backgroundColor: "#f2f2f9",
          },
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          "&.hover:hover": {
            backgroundColor: theme.palette.primary.superLight,
          },
        },
        head: {
          "&:hover": {
            backgroundColor: "transparent",
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: theme.palette.text.primary,

          transition: "all 0.2s ease-out",
          "&:hover": {
            color: theme.palette.primary.dark,
            transition: "all 0.2s ease-out",
            cursor: "pointer",
          },
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          cursor: "pointer",
          transition: "all 0.4s ease-out",
          backgroundColor: "transparent",
          "&.Mui-selected": {
            backgroundColor: "transparent",
            color: theme.palette.primary.main,
            "&:hover": {
              backgroundColor: "transparent",
              color: theme.palette.primary.main,
            },
          },
          "&:hover": {
            color: theme.palette.primary.main,
          },
        },
      },
      MuiListItemButton: {
        styleOverrides: {
          root: {
            cursor: "pointer",
            transition: "all 0.4s ease-out",
            backgroundColor: "transparent",
            "&:hover": {
              backgroundColor: "transparent !important",
            },
          },
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          "&.selected": {
            color: theme.palette.primary.main,
          },
          "&:hover": {
            backgroundColor: "transparent",
            color: theme.palette.primary.main,
          },
        },
      },
    },

    MuiAccordion: {
      styleOverrides: {
        root: {
          paddingTop: 0,
          borderBottom: 0,
          "& .Mui-expanded": {
            borderBottom: 0,
            transform: "rotate(0deg)",
          },
          backgroundColour: "red !important",
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          backgroundColour: "red !important",
          border: "0",

          padding: "0px",
        },
        content: {
          backgroundColour: "red",
        },
      },
    },

    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: "30px !important",
          padding: "20px",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          cursor: "pointer",
          borderRadius: 55,
          padding: "10px 20px",
        },
        text: {
          "&:hover": {
            backgroundColor: "transparent",
            color: theme.palette.primary.dark,
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          "&:hover": {
            backgroundColor: "transparent",
          },
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          ".MuiTableCell-head": {
            fontWeight: "800",
          },
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          overflowX: "none",
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          backgroundColor: "transparent",
        },
        filled: {
          borderRadius: 20,
          width: "100%",

          backgroundColor: "#FFFFFF",
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: "transparent",
          "&:before": {
            borderBottom: 0,
          },
          "&:hover": {
            backgroundColor: "transparent",
            borderBottom: 0,
            "&:before": {
              borderBottom: 0,
            },
          },
          "&:selected": {
            backgroundColor: "transparent",
            "&:hover": {
              backgroundColor: "transparent",
            },
          },
        },
      },
    },
    MuiFormLabel: {
      root: {
        color: "#000000",
      },
      focused: {
        color: "#000000",
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          borderRadius: 5,
          backgroundColor: "#FFFFFF",
          ml: 20,
          top: "5px",
          padding: "0 2px",
        },
        filled: {
          focused: {
            color: "#00000",
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          borderRadius: 15,
          backgroundColor: "#ffffff",
          border: 0,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          color: "#000000",
          borderRadius: 15,
          backgroundColor: "#ffffff",
          border: 0,
          paddingLeft: "5px",
          paddingTop: "5px",
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          paddingLeft: 15,
        },
      },
    },
  },
});

export default theme;
