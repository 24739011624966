import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Paper,
  Typography,
  Stack,
  IconButton,
  Tooltip,
} from "@mui/material";
import ChevronDown from "../components/presentation/icons/ChevronDown";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import classNames from "classnames";
import styles from "./Rings.module.css";
import Editing from "./Editing";
import ModalShare from "./ModalShare";
import ActivityRings from "react-activity-rings";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { useDispatch, useSelector } from "react-redux";
import { baseURL } from "../config";
import { Link, useParams } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import {
  getSingleApplication,
  updateRingProgressIndex,
} from "../actions/insurance";
import ProgressBar from "./ProgressBar";
import useHasPermission from "../hooks/useHasPermission";
import { allPermissions } from "./constants";
import { hasHttps } from "./checkImageUrl";

const RingsProgress = () => {
  const { applicationId } = useParams();
  const pathname = window.location.pathname;
  const [animate, setAnimate] = useState(false);
  const toggleRingWindow = () => {
    setAnimate(!animate);
    setMinimize(false);
  };

  const { singleApplication } = useSelector((state) => state.insurance);
  const ringRef = useRef();
  const dispatch = useDispatch();

  const { allUserList, roleData } = useSelector((store) => store.user);

  const [minimize, setMinimize] = useState(true);

  const handleClickAway = () => {
    setMinimize(true);
    setAnimate(false);
  };

  const canDelegateApplication = useHasPermission({
    permissionName: allPermissions.CAN_DELEGATE_APPLICATION,
  });

  const [ringProgressIndex, setRingProgressIndex] = useState(0);

  useEffect(() => {
    !singleApplication.section_progress &&
      // pathname.includes("section") &&
      dispatch(getSingleApplication(applicationId));
  }, [applicationId, dispatch, pathname, singleApplication.section_progress]);

  const sectionProgress =
    singleApplication?.section_progress &&
    [...singleApplication?.section_progress].reverse();

  const activityData = sectionProgress?.map((val, i) => ({
    label: val.section_title,
    value: val.percentage_completion / 100 || 0.001,
    color: val.color,
  }));

  const activityConfig = {
    width: minimize ? 80 : animate ? 250 : 150,
    height: minimize ? 80 : animate ? 250 : 150,
    radius: minimize ? 14 : animate ? 50 : 28,
    ringSize: minimize ? 7 : animate ? 20 : 14,
  };

  const onHover = () => {
    setMinimize(false);
  };

  const onMouseLeave = () => {
    !minimize && !animate && setMinimize(true);
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Paper
        sx={{
          width: !animate ? "auto" : "auto",
          borderBottomLeftRadius: 20,
          borderBottomRightRadius: 20,
          position: "absolute",
          top: 40,
          right: 30,
          pt: 4,
          pl: minimize ? 0 : 2,
          pr: minimize ? 0 : 2,
          pb: minimize ? 0 : 2,
          zIndex: 50,
          height: animate ? "100vh" : "auto",
          overflow: animate ? "auto" : "hidden",
        }}
        align="center"
      >
        <Box
          onMouseEnter={onHover}
          onMouseLeave={onMouseLeave}
          ref={ringRef}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: 2,
          }}
        >
          {!minimize && (
            <Typography
              sx={{ fontWeight: "bold", fontSize: 18 }}
              component="p"
              variant="body"
            >
              Your Progress:
            </Typography>
          )}
          {animate && (
            <Box sx={{ position: "absolute", right: "16px" }}>
              <IconButton onClick={toggleRingWindow} sx={{ padding: 0 }}>
                <CloseIcon />
              </IconButton>
            </Box>
          )}
        </Box>

        {/* ring */}
        <Box onMouseEnter={onHover} onMouseLeave={onMouseLeave} ref={ringRef}>
          <Box
            onMouseEnter={onHover}
            onMouseLeave={onMouseLeave}
            ref={ringRef}
            sx={{
              p: 0,
              width: minimize ? 80 : animate ? 250 : 150,
              height: minimize ? 80 : animate ? 250 : 150,
            }}
            className={classNames(styles.expand, animate && styles.grower)}
          >
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <ActivityRings
                data={activityData}
                config={activityConfig}
                className={classNames(
                  styles.ringsCase,
                  animate && styles.ringsCaseExpand
                )}
              />
            </Box>
          </Box>
          <Box onMouseEnter={onHover} onMouseLeave={onMouseLeave} ref={ringRef}>
            <ChevronDown
              style={{
                // position: animate ? "fixed" : "static",
                // bottom: animate ? "20px" : "0px",
                display: animate && "none",
              }}
              onClick={toggleRingWindow}
              // className={classNames(styles.rotate, animate && styles.reverse)}
            />
          </Box>
        </Box>
        <Box
          sx={{ p: 0 }}
          className={classNames(styles.animate, animate && styles.grow)}
        >
          <Box
            display={animate ? "visible" : "none"}
            sx={{ mb: 10, p: 3 }}
            className={classNames(
              styles.visible,
              animate && styles.visibleShow
            )}
          >
            {singleApplication?.section_progress?.map((section, i) => {
              return (
                <Box key={i} sx={{ mt: 5 }}>
                  <ProgressBar
                    color={section.color}
                    percentage={section.percentage_completion?.toFixed()}
                  />
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                  >
                    <Stack
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      spacing={2}
                      sx={{ mt: 2 }}
                    >
                      <Typography
                        sx={{ fontSize: 16 }}
                        align="left"
                        color={section.color}
                      >
                        {section.section_title}
                      </Typography>
                      <img
                        alt=""
                        src={
                          hasHttps(section?.icon_image)
                            ? section?.icon_image
                            : `${baseURL}${section?.icon_image ?? ""}`
                        }
                        style={{
                          width: "20px",
                          height: "20px",
                          objectFit: "contain",
                        }}
                      />
                    </Stack>
                    <Typography
                      sx={{ pt: 2, fontSize: 20 }}
                      align="left"
                      color={section.color}
                    >
                      {section.percentage_completion?.toFixed()}%
                    </Typography>
                  </Stack>
                  <Table>
                    <TableBody>
                      {section.refquestionsubsection_set?.map(
                        (subSection, i) => {
                          return (
                            <TableRow
                              key={i}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell
                                sx={{ pl: 0, border: "none" }}
                                align="left"
                              >
                                <Stack
                                  direction="row"
                                  justifyContent="flex-start"
                                  alignItems="center"
                                  spacing={2}
                                >
                                  {subSection.is_disable ? (
                                    <Link
                                      style={{
                                        textDecoration: "underline",
                                        color: "gray",
                                      }}
                                      variant="button"
                                    >
                                      <Typography sx={{ fontSize: 12 }}>
                                        {subSection.sub_section_title}
                                      </Typography>
                                    </Link>
                                  ) : (
                                    <Link
                                      onClick={() => {
                                        // dispatch(setIsSkip(true));
                                        setRingProgressIndex(
                                          (prev) => prev + 1
                                        );
                                        dispatch(
                                          updateRingProgressIndex(
                                            ringProgressIndex
                                          )
                                        );
                                      }}
                                      style={{
                                        textDecoration: "underline",
                                        color: "black",
                                      }}
                                      variant="button"
                                      to={{
                                        pathname: `/application/${applicationId}/answer-question/${subSection.id}`,
                                        state: {
                                          showFirstQuestion: true,
                                          iconcolor: section.color,
                                          hovercolor: section.hovercolor,
                                          subSection: subSection,
                                          section: section,
                                          icon_image: subSection?.icon_image,
                                        },
                                      }}
                                    >
                                      <Typography sx={{ fontSize: 12 }}>
                                        {subSection.sub_section_title}
                                      </Typography>
                                    </Link>
                                  )}
                                  <Editing
                                    userIsEditing={subSection.userIsEditing}
                                    sharedUserIsEditing={
                                      subSection.sharedUserIsEditing
                                    }
                                  />
                                </Stack>
                                {subSection?.skipped_questions?.length > 0 && (
                                  <Link
                                    to={{
                                      pathname: `/application/${applicationId}/answer-question/${subSection.id}`,
                                      state: {
                                        iconcolor: section.color,
                                        hovercolor: section.hovercolor,
                                        subSection: subSection,
                                        section: section,
                                        icon_image: subSection?.icon_image,
                                        showSkipQuestion: true,
                                        skippedQuestionId:
                                          subSection?.skipped_questions?.[0],
                                      },
                                    }}
                                    style={{ textDecoration: "none" }}
                                  >
                                    <Stack mt={1} direction="row" gap={1}>
                                      <Box>
                                        <InfoOutlinedIcon
                                          sx={{
                                            fill: "red",
                                            height: "15px",
                                            width: "15px",
                                          }}
                                        />
                                      </Box>
                                      <Typography
                                        sx={{
                                          fontWeight: "bold",
                                          fontSize: "0.6rem",
                                          color: "red",
                                          mt: "3px",
                                        }}
                                      >
                                        {subSection?.skipped_questions?.length >
                                        1
                                          ? "Click here to view Skipped questions"
                                          : "Click here to view Skipped question"}
                                      </Typography>
                                    </Stack>
                                  </Link>
                                )}
                              </TableCell>

                              <TableCell
                                onClick={
                                  canDelegateApplication
                                    ? handleClickAway
                                    : () => {}
                                }
                                sx={{ width: 15, border: "none" }}
                                align="right"
                              >
                                {/* <Send
                              sx={{
                                fontSize: 15,
                                "&:hover": { color: section.color },
                              }}
                            /> */}
                                {!subSection.is_disable && (
                                  <ModalShare
                                    contributors={subSection?.contributors}
                                    fromSection={false}
                                    subSectionTitle={
                                      subSection.sub_section_title
                                    }
                                    sectionTitle={section?.section_title}
                                    sectionId={section?.id}
                                    subSectionId={subSection.id}
                                    iconcolor={section.color}
                                    hovercolor={section.hovercolor}
                                    roleData={roleData}
                                    allUserList={allUserList}
                                  />
                                )}
                              </TableCell>
                              <TableCell
                                sx={{ width: 15, border: "none" }}
                                align="right"
                                color={subSection.is_disable && "gray"}
                              >
                                {subSection.percentage_completion?.toFixed()}%
                              </TableCell>
                            </TableRow>
                          );
                        }
                      )}
                    </TableBody>
                  </Table>
                </Box>
              );
            })}
          </Box>
        </Box>
      </Paper>
    </ClickAwayListener>
  );
};

export default RingsProgress;
