import { combineReducers } from "redux";
import administrationReducer from "./administrationReducer";
import availabilityReducer from "./availabilityReducer";
import authReducer from "./authReducer";
import briefcaseReducer from "./briefcaseReducer";
import caseReducer from "./caseReducer";
import eventReducer from "./eventReducer";
import contactReducer from "./contactReducer";
import chatReducer from "./chatReducer";
import commonReducer from "./commonReducers";
import globalReducer from "./globalReducer";
import marketplaceReducer from "./marketplaceReducer";
import userReducer from "./userReducer";
import insuranceReducer from "./insuranceReducer";
import policyReducer from "./policyReducer";
import companyReducer from "./companyReducer";
import newsReducer from "./news";
import claimsReducer from "./claimsReducer";

export default combineReducers({
  business: administrationReducer,
  availability: availabilityReducer,
  auth: authReducer,
  briefcase: briefcaseReducer,
  case: caseReducer,
  event: eventReducer,
  company: companyReducer,
  contacts: contactReducer,
  chat: chatReducer,

  common: commonReducer,
  global: globalReducer,

  marketplace: marketplaceReducer,

  user: userReducer,

  insurance: insuranceReducer,
  policy: policyReducer,
  news: newsReducer,
  claims: claimsReducer,
});
