import { useSelector } from "react-redux";

export const useBusinessName = () => {
  const { loggedUserData } = useSelector((store) => store.auth);
  const businessFirstName =
    loggedUserData?.user_data?.party_business?.name?.split(" ")?.[0] ?? "";
  return businessFirstName;
};

export const useFirstName = () => {
  const { loggedUserData } = useSelector((store) => store.auth);
  const firstName = loggedUserData?.user_data?.first_name ?? "";
  return firstName;
};

export const useLastName = () => {
  const { loggedUserData } = useSelector((store) => store.auth);
  const lastName = loggedUserData?.user_data?.last_name ?? "";
  return lastName;
};

export const useInvoffUserid = () => {
  const { loggedUserData } = useSelector((store) => store.auth);
  const lastName = loggedUserData?.user_data?.invoffuserid ?? "";
  return lastName;
};

export const useBusinessId = () => {
  const { loggedUserData } = useSelector((store) => store.auth);
  const businessId = loggedUserData?.user_data?.party_business?.id ?? "";
  return businessId;
};

export const useCompanyName = () => {
  const { loggedUserData } = useSelector((store) => store.auth);
  return loggedUserData?.user_data?.company?.name ?? "";
};

export const useCompanyId = () => {
  const { loggedUserData } = useSelector((store) => store.auth);
  return loggedUserData?.user_data?.company?.id ?? "";
};
