const initialState = {
  briefcaseList: {},
  briefcaseDetail: {},
  folderList: [],
  allFolderList: [],
  briefcaseEvents: [],
  briefcaseCaseList: [],
  folderCaseList: [],
  logList:[]
};

export default function (state = initialState, action) {
  switch (action.type) {
    case "BRIEFCASE_LIST":
      return {
        ...state,
        briefcaseList: action.payload.data,
      };
    case "GET_BRIEFCASE_BY_ID":
      return {
        ...state,
        //briefcaseList: state.briefcase.push(action.payload.data),
        briefcaseDetail: action.payload,
      };
    case "FOLDER_LIST":
      return {
        ...state,
        //briefcaseList: state.briefcase.push(action.payload.data),
        folderList: action.payload,
      };
    case "ALL_FOLDER_LIST":
      return {
        ...state,
        //briefcaseList: state.briefcase.push(action.payload.data),
        allFolderList: action.payload,
      };
    case "DOC_UPLOADED":
      var doc2 = { ...state.briefcaseDetail };
      doc2.documents = action.payload.data;
      return {
        ...state,
        briefcaseDetail: doc2,
      };
    case "BRIEFCASE_EVENTS":
      return {
        ...state,
        briefcaseEvents: action.payload,
      };
    case "BRIEFCASE_CASE_LIST":
      return {
        ...state,
        briefcaseCaseList: action.payload.data,
      };
    case "FOLDER_CASE_LIST":
      return {
        ...state,
        folderCaseList: action.payload.data,
      };
      case "LOGS_LIST":
        return {
          ...state,
          logList: action.payload.data,
        };
    default:
      return state;
  }
}
