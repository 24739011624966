const initialState = {
  usersList: [],
  allUserList: [],
  userDetail: {},
  addEditUserRes: {},
  deleteUserRes: {},
  roleData: [],
  getUserLoading: false,
  updateSingleUserLoading: false,
  addSingleUserLoading: false,
};

const userReducer = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case "GET_USERS":
      return {
        ...state,
        usersList: payload,
      };
    case "GET_ALL_USERS":
      return {
        ...state,
        allUserList: payload,
      };
    case "GET_USER_BY_ID":
      return {
        ...state,
        userDetail: payload,
      };

    case "UPDATE_SINGLE_USER_LOADING":
      return {
        ...state,
        updateSingleUserLoading: payload,
      };

    case "ADD_SINGLE_USER_LOADING":
      return {
        ...state,
        addSingleUserLoading: payload,
      };

    case "GET_USER_LOADING":
      return {
        ...state,
        getUserLoading: payload,
      };

    case "ADD_EDIT_USER":
      return {
        ...state,
        addEditUserRes: payload,
      };
    case "DEACTIVATE_USER":
      return {
        ...state,
        deleteUserRes: payload,
      };

    case "GET_ROLES":
      return {
        ...state,
        roleData: payload,
      };
    default:
      return state;
  }
};

export default userReducer;
