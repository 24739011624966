import React from "react";
import EditingUser from "../components/presentation/icons/EditingUser";
import EditingShared from "../components/presentation/icons/EditingShared";

export default function Editing(props) {
  return (
    <>
      {props.userIsEditing && <EditingUser sx={{ fontSize: 30 }} />}
      {props.sharedUserIsEditing && <EditingShared sx={{ fontSize: 30 }} />}
    </>
  );
}
