const initialState = {
  allSection: {},
  allSubSection: {},
  allQuestions: [],
  allApplication: [],
  singleApplication: {},
  singleQuestionData: [],
  repeatQuestionValue: {},
  sectionQuestion: [],
  consentDocument: [],
  updateSubjLoading: false,
  fcaDetails: [],
  fcaLoading: false,
  applicationResources: [],
  systemVideos: [],
  systemGuides: [],
  subStages: [],
  sectionQuestionLoad: false,
  ringProgressIndex: 0,
  loading: false,
  singleQuestionLoading: false,
  resourcesLoading: false,
  showSnackbar: "",
  getTime: "",
  isShowSkip: false,
  addApplicationLoading: false,
  singleQuote: [],
  singleQuoteLoading: false,
  acceptQuoteLoading: false,
  paymentFailed: false,
  isOpenResources: false,
  submitLoading: false,
  undelegateLoading: false,
  paymentLoading: false,
  choosePaymentLoading: false,
  internalUser: [],
  paymentSchemaDetails: [],
  quoteDocument: [],
  dashboardData: [],
  dashboardLoading: false,
  addUserLoading: false,
  inviteUserLoading: false,
  delegateUserLoading: false,
};

const insuranceReducer = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case "SHOW_PAGE_LOADER":
      return {
        ...state,
        isPageLoading: true,
      };
    case "HIDE_PAGE_LOADER":
      return {
        ...state,
        isPageLoading: false,
      };
    case "GET_ALL_QUESTION":
      return {
        ...state,
        allQuestions: payload,
      };
    case "UNDELEGATE_LOADING":
      return {
        ...state,
        undelegateLoading: payload,
      };
    case "SET_IS_SKIP":
      return {
        ...state,
        isShowSkip: payload,
      };
    case "GET_SECTION_QUESTION":
      return {
        ...state,
        sectionQuestion: payload,
      };
    case "GET_QUOTE_DOCUMENT":
      return {
        ...state,
        quoteDocument: payload,
      };

    case "GET_DASHBOARD_DETAILS":
      return {
        ...state,
        dashboardData: payload,
      };
    case "GET_SYSTEM_VIDEOS":
      return {
        ...state,
        systemVideos: payload,
      };
    case "GET_SYSTEM_GUIDES":
      return {
        ...state,
        systemGuides: payload,
      };
    case "GET_DASHBOARD_LOADING":
      return {
        ...state,
        dashboardLoading: payload,
      };

    case "GET_PAYMENT_DETAILS":
      return {
        ...state,
        paymentSchemaDetails: payload,
      };
    case "GET_SUBSTAGES":
      return {
        ...state,
        subStages: payload,
      };
    case "RING_PROGRESS_INDEX":
      return {
        ...state,
        ringProgressIndex: payload + 1,
      };
    case "GET_CONSENT_DOCUMENT":
      return {
        ...state,
        consentDocument: payload,
      };
    case "UPDATE_SUBJ_LOADING": {
      return {
        ...state,
        updateSubjLoading: payload,
      };
    }
    case "SECTION_QUESTION_LOADING":
      return {
        ...state,
        sectionQuestionLoad: payload,
      };
    case "GET_PAYMENT_LOADING":
      return {
        ...state,
        paymentLoading: payload,
      };
    case "PAYMENT_FAILED":
      return {
        ...state,
        paymentFailed: payload,
      };
    case "SUBMIT_QUESTION_LOADING":
      return {
        ...state,
        submitLoading: payload,
      };
    case "CHOOSE_PAYMENT_LOADING":
      return {
        ...state,
        choosePaymentLoading: payload,
      };
    case "GET_FCA_DETAILS":
      return {
        ...state,
        fcaDetails: payload,
      };

    case "GET_INTERNAL_USER":
      return {
        ...state,
        internalUser: payload,
      };

    case "ADD_APPLICATION_LOADING":
      return {
        ...state,
        addApplicationLoading: payload,
      };

    case "FCA_LOADING_REQ":
      return {
        ...state,
        fcaLoading: true,
      };
    case "FCA_LOADING_SUC":
      return {
        ...state,
        fcaLoading: false,
      };
    case "FCA_LOADING_FAIL":
      return {
        ...state,
        fcaLoading: false,
      };
    case "RESOURCES_LOADING":
      return {
        ...state,
        resourcesLoading: payload,
      };
    case "GET_SINGLE_QUOTE_LOADING":
      return {
        ...state,
        singleQuoteLoading: payload,
      };
    case "GET_SINGLE_QUOTE":
      return {
        ...state,
        singleQuote: payload,
      };
    case "APPLICATION_RESOURCES":
      return {
        ...state,
        applicationResources: payload,
      };
    case "SINGLE_APP_LOADING":
      return {
        ...state,
        sectionAPPLoad: payload,
      };

    case "GET_APPLICATION_REQ":
      return {
        ...state,
        loading: true,
      };
    case "GET_APPLICATION":
      return {
        ...state,
        allApplication: payload,
        loading: false,
      };
    case "GET_APPLICATION_FAIL":
      return {
        ...state,
        loading: false,
      };
    case "GET_SINGLE_APPLICATION":
      return {
        ...state,
        singleApplication: payload,
      };
    case "ADD_UPDATE_APPLICATION":
      return {
        ...state,
        singleApplication: payload,
      };
    case "GET_QUESTIONARIES":
      return {
        ...state,
        singleQuestionData: payload,
      };

    case "REPEAT_QUESTION":
      return {
        ...state,
        repeatQuestionValue: Object.assign(state.repeatQuestionValue, payload),
        // repeatQuestionValue: payload,
      };
    case "LOADING":
      return {
        ...state,
        loading: payload,
      };
    case "SINGLE_LOADING":
      return {
        ...state,
        singleQuestionLoading: payload,
      };
    case "ACCEPT_QUOTE_LOADING":
      return {
        ...state,
        acceptQuoteLoading: payload,
      };
    case "SHOW_SNACKBAR":
      return {
        ...state,
        showSnackbar: payload,
      };
    case "GET_TIME":
      return {
        ...state,
        getTime: payload,
      };
    case "ADD_USER_LOADING":
      return {
        ...state,
        addUserLoading: payload,
      };

    case "INVITE_USER_LOADING":
      return {
        ...state,
        inviteUserLoading: payload,
      };
    case "DELEGATE_USER_LOADING":
      return {
        ...state,
        delegateUserLoading: payload,
      };
    case "SHOW_RESOURCES_DRAWER":
      return {
        ...state,
        isOpenResources: payload,
      };
    default:
      return state;
  }
};

export default insuranceReducer;
