const initialState = {
  eventData: [],
  user_status: {},
  requestTimeSlotLoading: false,
  userContent: [],
  queryType: [],
  availableSlot: [],
};

const availabilityReducer = function (state = initialState, action) {
  switch (action.type) {
    case "AVAIL_EVENT_LIST":
      return {
        ...state,
        eventData: action.payload.data,
      };
    case "AVAIL_USER_CONTENT":
      return {
        ...state,
        userContent: action.payload,
      };
    case "REQUEST_LOADING":
      return {
        ...state,
        requestTimeSlotLoading: action.payload,
      };
    case "AVAIL_GET_MULTI_AVAIL_USER":
      return {
        ...state,
        user_status: action.payload,
      };
    case "GET_QUERY_TYPE":
      return {
        ...state,
        queryType: action.payload,
      };
    case "GET_AVAILABLE_SLOT":
      return {
        ...state,
        availableSlot: action.payload,
      };

    default:
      return state;
  }
};

export default availabilityReducer;
